import * as tslib_1 from "tslib";
import { AbstractBuilder } from './abstract.builder';
import { ParecerResponsavel } from '../model/parecerresponsavel.model';
var ParecerResponsavelBuilder = /** @class */ (function (_super) {
    tslib_1.__extends(ParecerResponsavelBuilder, _super);
    function ParecerResponsavelBuilder() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ParecerResponsavelBuilder.prototype.reset = function () {
        this.entity = new ParecerResponsavel(null, null, null, null, null, null, null);
    };
    return ParecerResponsavelBuilder;
}(AbstractBuilder));
export { ParecerResponsavelBuilder };
