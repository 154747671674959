import * as tslib_1 from "tslib";
import { GenericService } from "src/app/core/services/generic.service";
var UsuarioService = /** @class */ (function (_super) {
    tslib_1.__extends(UsuarioService, _super);
    function UsuarioService(http, router, cacheService) {
        var _this = _super.call(this, http, router) || this;
        _this.http = http;
        _this.router = router;
        _this.cacheService = cacheService;
        _this.setEntityType("r/usuario");
        return _this;
    }
    UsuarioService.prototype.getCurrentUser = function () {
        var _this = this;
        var cacheKey = "currentUser";
        // Verifica se os dados já estão no cache
        if (this.cacheService.has(cacheKey)) {
            return Promise.resolve(this.cacheService.get(cacheKey));
        }
        // Caso não tenha no cache, faz a requisição HTTP
        return this.http
            .get(this.apiURL + "/currentuser", {
            headers: this.getHeaders(),
        })
            .toPromise()
            .then(function (response) {
            _this.cacheService.set(cacheKey, response); // Armazena no cache
            return response;
        });
    };
    return UsuarioService;
}(GenericService));
export { UsuarioService };
