import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { PessoaBuilder } from 'src/app/builder/pessoa.builder';
var QuestionarioComponent = /** @class */ (function () {
    function QuestionarioComponent(usuarioService, pessoaService, 
    //public questionarioBuilder: QuestionarioBuilder,
    questionarioService, route, router, swtAlert2Service) {
        this.usuarioService = usuarioService;
        this.pessoaService = pessoaService;
        this.questionarioService = questionarioService;
        this.route = route;
        this.router = router;
        this.swtAlert2Service = swtAlert2Service;
        this.totalDePaginas = 1;
        this.pessoaBuilder = new PessoaBuilder();
        //    this.questionarios = new Array<any>();
        //    this.questionario = this.questionarioBuilder.getInstance();
    }
    QuestionarioComponent.prototype.ngOnInit = function () {
        var url = this.route.snapshot.url.map(function (segment) { return segment.path; }).join('/');
        // Verifica se o caminho contém celula ou casacomunitaria
        if (url.includes('celula')) {
            this.origem = 'celula';
        }
        else if (url.includes('casacomunitaria')) {
            this.origem = 'casacomunitaria';
        }
        this.initializer();
    };
    QuestionarioComponent.prototype.initializer = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.carregarUsuario()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.carregarPessoa()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.buscarQuestionarios()];
                    case 3:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioComponent.prototype.buscarQuestionarios = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.questionarioService.buscarAbertos()];
                    case 1:
                        response = _a.sent();
                        this.questionarios = response.content;
                        this.totalDePaginas = response.totalPages;
                        return [3 /*break*/, 3];
                    case 2:
                        err_1 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_1.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    /*  carregarQuestionarioConfiguracao(questionarioId: number) {
        const missaoId = this.pessoa.vinculoMissao.missaoAtualId;
        this.missaoConfiguracaoService
          .obterConfiguracaoQuestionarioPorMissaoEQuestionario(
            missaoId,
            questionarioId
          )
          .subscribe(
            (questionarioConfiguracao) =>
              (this.questionarioConfiguracao = questionarioConfiguracao)
          );
      }
    */
    QuestionarioComponent.prototype.carregarUsuario = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.usuarioService.getCurrentUser()];
                    case 1:
                        response = _a.sent();
                        this.usuario = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        console.log(e_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioComponent.prototype.carregarPessoa = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.pessoaService.buscaPorUsuarioId(this.usuario.id)];
                    case 1:
                        response = _a.sent();
                        this.pessoa = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        e_2 = _a.sent();
                        console.log(e_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    return QuestionarioComponent;
}());
export { QuestionarioComponent };
