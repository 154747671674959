import * as tslib_1 from "tslib";
import { GenericService } from "src/app/core/services/generic.service";
import { environment } from "src/environments/environment";
var ParecerResponsavelService = /** @class */ (function (_super) {
    tslib_1.__extends(ParecerResponsavelService, _super);
    function ParecerResponsavelService(http, router) {
        var _this = _super.call(this, http, router) || this;
        _this.http = http;
        _this.router = router;
        _this.setEntityType("parecerresponsavel");
        return _this;
    }
    ParecerResponsavelService.prototype.buscarPorPessoaId = function (id) {
        return this.http
            .get(environment.moduloFormacao.urlModuloFormacao + "parecer_responsavel/porpessoaid/" + id, { headers: this.getHeaders() })
            .toPromise();
    };
    ParecerResponsavelService.prototype.buscarPorQuestionarioFormandoId = function (id) {
        return this.http
            .get(environment.moduloFormacao.urlModuloFormacao + "parecer_responsavel/porquestionarioid/" + id, { headers: this.getHeaders() })
            .toPromise();
    };
    ParecerResponsavelService.prototype.buscarPorId = function (id) {
        return this.http
            .get(environment.moduloFormacao.urlModuloFormacao + "parecer_responsavel/" + id, { headers: this.getHeaders() })
            .toPromise();
    };
    ParecerResponsavelService.prototype.buscaPublicaPorId = function (id) {
        return this.http
            .get(environment.moduloFormacao.urlModuloFormacao + "parecer_responsavel/pb/" + id)
            .toPromise();
    };
    ParecerResponsavelService.prototype.cadastrar = function (request) {
        return this.http
            .post(environment.moduloFormacao.urlModuloFormacao + "parecer_responsavel", JSON.stringify(request), { headers: this.getHeaders() })
            .toPromise();
    };
    ParecerResponsavelService.prototype.atualizar = function (id, request) {
        return this.http
            .put(environment.moduloFormacao.urlModuloFormacao + "parecer_responsavel/" + id, JSON.stringify(request), { headers: this.getHeaders() })
            .toPromise();
    };
    return ParecerResponsavelService;
}(GenericService));
export { ParecerResponsavelService };
