import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
var ColegiadoComponent = /** @class */ (function () {
    function ColegiadoComponent(usuarioService, imagemService, pessoaService, coordenadorColegiadoService, swtAlert2Service) {
        this.usuarioService = usuarioService;
        this.imagemService = imagemService;
        this.pessoaService = pessoaService;
        this.coordenadorColegiadoService = coordenadorColegiadoService;
        this.swtAlert2Service = swtAlert2Service;
    }
    ColegiadoComponent.prototype.ngOnInit = function () {
        this.initializer();
        this.paginacaoConfig = {
            page: 0,
            size: 10,
            totalPages: 0,
            totalElements: 0,
        };
    };
    ColegiadoComponent.prototype.carregarUsuario = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.usuarioService.getCurrentUser()];
                    case 1:
                        response = _a.sent();
                        this.usuario = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        console.log(e_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ColegiadoComponent.prototype.carregarPessoa = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.pessoaService.buscaPorUsuarioId(this.usuario.id)];
                    case 1:
                        response = _a.sent();
                        this.pessoa = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        e_2 = _a.sent();
                        console.log(e_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ColegiadoComponent.prototype.initializer = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.carregarUsuario()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.carregarPessoa()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.carregarFormadoresCelula()];
                    case 3:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ColegiadoComponent.prototype.carregarFormadoresCelula = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, i, blogImage, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 7, , 8]);
                        return [4 /*yield*/, this.coordenadorColegiadoService.obterFormadoresPorMissaoDoCoordenadorColegiado(this.paginacaoConfig.page, this.paginacaoConfig.size)];
                    case 1:
                        response = _a.sent();
                        this.formadoresDaMissao = response.content;
                        this.paginacaoConfig.totalPages = response.totalPages;
                        this.paginacaoConfig.totalElements = response.totalElements;
                        if (this.formadoresDaMissao.length > 0) {
                            this.formadoresDaMissao = this.formadoresDaMissao.sort(function (a, b) {
                                return a.pessoaNome.localeCompare(b.pessoaNome);
                            });
                        }
                        i = 0;
                        _a.label = 2;
                    case 2:
                        if (!(i < this.formadoresDaMissao.length)) return [3 /*break*/, 6];
                        if (!(this.formadoresDaMissao[i].imagemId != null)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.getPessoaImagem(this.formadoresDaMissao[i].imagemId)];
                    case 3:
                        blogImage = _a.sent();
                        this.imagemService.createImage(blogImage, this.formadoresDaMissao[i]);
                        _a.label = 4;
                    case 4:
                        if (this.formadoresDaMissao[i].questionarioFormandoStatus == "NAOINICIADO") {
                            this.formadoresDaMissao[i].questionarioFormandoStatus =
                                "Não Iniciado";
                        }
                        if (this.formadoresDaMissao[i].parecerStatus == "NAOINICIADO") {
                            this.formadoresDaMissao[i].parecerStatus = "Não Iniciado";
                        }
                        _a.label = 5;
                    case 5:
                        i++;
                        return [3 /*break*/, 2];
                    case 6: return [3 /*break*/, 8];
                    case 7:
                        err_1 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_1.error.errors);
                        return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    ColegiadoComponent.prototype.getPessoaImagem = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.imagemService.getPessoaPhoto(id)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.body];
                    case 2:
                        err_2 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_2.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ColegiadoComponent.prototype.cliqueNoBotaoListener = function (botao) {
        this.paginacaoConfig.page = botao.numero - 1;
        this.carregarFormadoresCelula();
    };
    return ColegiadoComponent;
}());
export { ColegiadoComponent };
