import * as tslib_1 from "tslib";
import { GenericService } from 'src/app/core/services/generic.service';
import { environment } from 'src/environments/environment';
var FormadorComunitarioService = /** @class */ (function (_super) {
    tslib_1.__extends(FormadorComunitarioService, _super);
    function FormadorComunitarioService(http, router, cacheService) {
        var _this = _super.call(this, http, router) || this;
        _this.http = http;
        _this.router = router;
        _this.cacheService = cacheService;
        _this.setEntityType('formadorcomunitario');
        return _this;
    }
    FormadorComunitarioService.prototype.buscarPorCelula = function (busca) {
        return this.http.post(this.apiURL + '/porcelula', JSON.stringify(busca), { 'headers': this.getHeaders() }).toPromise();
    };
    FormadorComunitarioService.prototype.buscarPorPessoaId = function (id) {
        return this.http.get(this.apiURL + "/porpessoa/" + id, { 'headers': this.getHeaders() }).toPromise();
    };
    FormadorComunitarioService.prototype.buscarCelulaPorPessoaId = function (id) {
        var _this = this;
        var cacheKey = 'buscarCelulaPorPessoaId-' + JSON.stringify(id);
        // Verifica se os dados já estão no cache
        if (this.cacheService.has(cacheKey)) {
            return Promise.resolve(this.cacheService.get(cacheKey));
        }
        // Caso não tenha no cache, faz a requisição HTTP
        return this.http.get(environment.moduloFormacao.urlModuloFormacao + "api/secured/formadorcomunitario/celula/porpessoaid/" + id, { 'headers': this.getHeaders() }).toPromise().then(function (response) {
            _this.cacheService.set(cacheKey, response); // Armazena no cache
            return response;
        });
    };
    FormadorComunitarioService.prototype.buscarCasaComunitariaPorPessoaId = function (id) {
        var _this = this;
        var cacheKey = 'buscarCasaComunitariaPorPessoaId-' + JSON.stringify(id);
        // Verifica se os dados já estão no cache
        if (this.cacheService.has(cacheKey)) {
            return Promise.resolve(this.cacheService.get(cacheKey));
        }
        // Caso não tenha no cache, faz a requisição HTTP
        return this.http.get(environment.moduloFormacao.urlModuloFormacao + "api/secured/formadorcomunitario/casacomunitaria/porpessoaid/" + id, { 'headers': this.getHeaders() }).toPromise().then(function (response) {
            _this.cacheService.set(cacheKey, response); // Armazena no cache
            return response;
        });
    };
    FormadorComunitarioService.prototype.buscarCelulaPorId = function (id) {
        return this.http.get(environment.moduloFormacao.urlModuloFormacao + "api/secured/formadorcomunitario/celula/porid/" + id, { 'headers': this.getHeaders() }).toPromise();
    };
    return FormadorComunitarioService;
}(GenericService));
export { FormadorComunitarioService };
