import * as tslib_1 from "tslib";
import { ElementRef, OnInit } from "@angular/core";
import { MembroComunidadeBuilder } from "src/app/builder/membro.comunidade.builder";
import { PessoaBuilder } from "src/app/builder/pessoa.builder";
var ParecerCelulaAutoridadeComponent = /** @class */ (function () {
    function ParecerCelulaAutoridadeComponent(missaoConfiguracaoService, usuarioService, imagemService, pessoaService, celulaService, membroService, questionarioFormandoBuilder, questionarioFormandoService, parecerResponsavelBuilder, parecerResponsavelService, elementoGrupoDominioLocalService, caminhoFormativoService, arquivoService, sanitizer, route, router, swtAlert2Service) {
        this.missaoConfiguracaoService = missaoConfiguracaoService;
        this.usuarioService = usuarioService;
        this.imagemService = imagemService;
        this.pessoaService = pessoaService;
        this.celulaService = celulaService;
        this.membroService = membroService;
        this.questionarioFormandoBuilder = questionarioFormandoBuilder;
        this.questionarioFormandoService = questionarioFormandoService;
        this.parecerResponsavelBuilder = parecerResponsavelBuilder;
        this.parecerResponsavelService = parecerResponsavelService;
        this.elementoGrupoDominioLocalService = elementoGrupoDominioLocalService;
        this.caminhoFormativoService = caminhoFormativoService;
        this.arquivoService = arquivoService;
        this.sanitizer = sanitizer;
        this.route = route;
        this.router = router;
        this.swtAlert2Service = swtAlert2Service;
        this.totalDePaginas = 1;
        this.pessoaBuilder = new PessoaBuilder();
        this.membrosCelula = new Array();
        this.membroComunidadeBuilder = new MembroComunidadeBuilder();
        this.membroComunidade = this.membroComunidadeBuilder.getInstance();
        this.questionarioFormando = this.questionarioFormandoBuilder.getInstance();
        this.parecerResponsavel = this.parecerResponsavelBuilder.getInstance();
    }
    ParecerCelulaAutoridadeComponent.prototype.ngOnInit = function () {
        this.initializer();
        this.hasCelulaAssociada = false;
    };
    ParecerCelulaAutoridadeComponent.prototype.initializer = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.carregarUsuario()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.carregarPessoa()];
                    case 2:
                        _a.sent();
                        this.celulaId = Number(this.route.snapshot.paramMap.get("id"));
                        return [4 /*yield*/, this.carregarCorStatusVocacional()];
                    case 3:
                        _a.sent();
                        return [4 /*yield*/, this.carregarMembrosCelula()];
                    case 4:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ParecerCelulaAutoridadeComponent.prototype.carregarQuestionarioConfiguracao = function (questionarioId) {
        var _this = this;
        var missaoId = this.pessoa.vinculoMissao.missaoAtualId;
        this.missaoConfiguracaoService
            .obterConfiguracaoQuestionarioPorMissaoEQuestionario(missaoId, questionarioId)
            .subscribe(function (questionarioConfiguracao) {
            return (_this.questionarioConfiguracao = questionarioConfiguracao);
        });
    };
    ParecerCelulaAutoridadeComponent.prototype.carregarUsuario = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.usuarioService.getCurrentUser()];
                    case 1:
                        response = _a.sent();
                        this.usuario = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        console.log(e_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCelulaAutoridadeComponent.prototype.carregarPessoa = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.pessoaService.buscaPorUsuarioId(this.usuario.id)];
                    case 1:
                        response = _a.sent();
                        this.pessoa = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        e_2 = _a.sent();
                        console.log(e_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCelulaAutoridadeComponent.prototype.carregarCorStatusVocacional = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, e_3;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        _a = this;
                        return [4 /*yield*/, this.elementoGrupoDominioLocalService.buscarPorGrupoDominioNomeModuloFormacao("COR_STATUS_VOCACIONAL")];
                    case 1:
                        _a.listaCorStatusVocacional =
                            _b.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        e_3 = _b.sent();
                        console.log(e_3);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCelulaAutoridadeComponent.prototype.carregarMembrosCelula = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, idMembros, _loop_1, this_1, i, err_1;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 7, , 8]);
                        _a = this;
                        return [4 /*yield*/, this.membroService.listarDetalhesPorCelulaIdFromView(this.celulaId)];
                    case 1:
                        _a.membrosCelula = _b.sent();
                        this.totalDePaginasMembros = 1;
                        if (this.membrosCelula.length > 0) {
                            this.membrosCelula = this.membrosCelula.sort(function (a, b) {
                                return a.pessoaNome.localeCompare(b.pessoaNome);
                            });
                        }
                        idMembros = this.membrosCelula.map(function (membro) { return membro.id; });
                        return [4 /*yield*/, this.carregarQuestionarioMembro(idMembros)];
                    case 2:
                        _b.sent();
                        console.log(this.questionariosMembros);
                        _loop_1 = function (i) {
                            var blogImage, questionario, cor;
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!(this_1.membrosCelula[i].imagemId != null)) return [3 /*break*/, 2];
                                        return [4 /*yield*/, this_1.getPessoaImagem(this_1.membrosCelula[i].imagemId)];
                                    case 1:
                                        blogImage = _a.sent();
                                        this_1.imagemService.createImage(blogImage, this_1.membrosCelula[i]);
                                        _a.label = 2;
                                    case 2:
                                        this_1.membrosCelula[i].editando = false;
                                        questionario = this_1.questionariosMembros.filter(function (questionarioFormando) {
                                            return questionarioFormando.id === _this.membrosCelula[i].id;
                                        });
                                        if (questionario.length > 0) {
                                            this_1.membrosCelula[i].statusQuestionario =
                                                questionario[0].status_questionario;
                                            this_1.membrosCelula[i].idQuestionarioFormando =
                                                questionario[0].questionarioFormandoId;
                                            this_1.membrosCelula[i].questionarioId = questionario[0].questionarioId;
                                            this_1.membrosCelula[i].statusParecerFC =
                                                questionario[0].status_parecer_fc;
                                            this_1.membrosCelula[i].idParecerFC = questionario[0].parecerIdFC;
                                            this_1.membrosCelula[i].acompanhadoPor = questionario[0].acompanhadoPor;
                                            this_1.membrosCelula[i].acompanhadoPorNome =
                                                questionario[0].acompanhadoPorNome;
                                            this_1.membrosCelula[i].arquivo = questionario[0].arquivo;
                                            this_1.membrosCelula[i].dataEnvioParecerFC =
                                                questionario[0].dataEnvioParecerFC;
                                            this_1.membrosCelula[i].dataEnvioQuestionario =
                                                questionario[0].dataEnvioQuestionario;
                                            this_1.membrosCelula[i].missaoAtualId = questionario[0].pessoamissaoAtualId;
                                            this_1.membrosCelula[i].questionarioNome = this_1.buscarNomeQuestionario(this_1.questionarioFormando.questionarioId);
                                            //this.membrosCelula[i].pedidoFormando = this.buscarNomePedido(questionario[0].indicacaoFC);
                                            this_1.membrosCelula[i].indicacaoFCNome = this_1.buscarNomePedido(questionario[0].indicacaoFC);
                                            this_1.membrosCelula[i].justificativaFC = questionario[0].justificativaFC;
                                            this_1.membrosCelula[i].indicacaoCD = questionario[0].indicacaoCD;
                                            this_1.membrosCelula[i].justificativaCD = questionario[0].justificativaCD;
                                            this_1.membrosCelula[i].indicacaoCL = questionario[0].indicacaoCL;
                                            this_1.membrosCelula[i].justificativaCL = questionario[0].justificativaCL;
                                            this_1.membrosCelula[i].isDiscernimentoGG = questionario[0].isDiscernimentoGG;
                                            this_1.membrosCelula[i].consideracaoConcorda = questionario[0].consideracaoConcorda;
                                            this_1.membrosCelula[i].caminhoFormativoFeedback = questionario[0].caminhoFormativoFeedback;
                                            this_1.membrosCelula[i].caminhoFormativoSugeridoFC = questionario[0].caminhoFormativoSugeridoFC;
                                            this_1.membrosCelula[i].consideracaoCL = questionario[0].consideracaoCL;
                                            this_1.membrosCelula[i].corStatusVocacionalId = questionario[0].corStatusVocacionalId;
                                            if (questionario[0].corStatusVocacionalId) {
                                                cor = this_1.listaCorStatusVocacional.find(function (c) { return c.id === questionario[0].corStatusVocacionalId; });
                                                this_1.membrosCelula[i].corStatusVocacionalValor = cor ? cor.valor : undefined;
                                                this_1.membrosCelula[i].corStatusVocacionalNome = cor ? cor.nome : undefined;
                                            }
                                        }
                                        if (this_1.membrosCelula[i].statusQuestionario == "NAOINICIADO"
                                            || this_1.membrosCelula[i].statusQuestionario == undefined
                                            || this_1.membrosCelula[i].statusQuestionario == null) {
                                            this_1.membrosCelula[i].statusQuestionario =
                                                "Não Iniciado";
                                        }
                                        if (this_1.membrosCelula[i].statusParecerFC == "NAOINICIADO"
                                            || this_1.membrosCelula[i].statusParecerFC == undefined
                                            || this_1.membrosCelula[i].statusParecerFC == null) {
                                            this_1.membrosCelula[i].statusParecerFC = "Não Iniciado";
                                        }
                                        return [2 /*return*/];
                                }
                            });
                        };
                        this_1 = this;
                        i = 0;
                        _b.label = 3;
                    case 3:
                        if (!(i < this.membrosCelula.length)) return [3 /*break*/, 6];
                        return [5 /*yield**/, _loop_1(i)];
                    case 4:
                        _b.sent();
                        _b.label = 5;
                    case 5:
                        i++;
                        return [3 /*break*/, 3];
                    case 6: return [3 /*break*/, 8];
                    case 7:
                        err_1 = _b.sent();
                        this.swtAlert2Service.errorAlert(err_1.error.errors);
                        return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCelulaAutoridadeComponent.prototype.buscarNomePedido = function (idPedido) {
        var nomePedido = '';
        if (idPedido) {
            switch (idPedido) {
                case 1:
                    nomePedido = 'Mudança de Nível';
                    break;
                case 2:
                    nomePedido = 'Permanecer no Nível';
                    break;
                case 3:
                    nomePedido = 'Realizar Primeiras Promessas';
                    break;
                case 4:
                    nomePedido = 'Realizar Promessas Definitivas';
                    break;
                case 5:
                    nomePedido = 'Desligamento CAL';
                    break;
                case 6:
                    nomePedido = 'Outro';
                    break;
                default:
                    nomePedido = null;
                    break;
            }
        }
        return nomePedido;
    };
    ParecerCelulaAutoridadeComponent.prototype.buscarNomeQuestionario = function (idQuestionario) {
        var nomeQuestionario = '';
        if (idQuestionario) {
            switch (idQuestionario) {
                case 4:
                    nomeQuestionario = 'Pedido para as Primeiras Promessas - CA';
                    break;
                case 5:
                    nomeQuestionario = 'Pedido para as Promessas Definitivas - CA';
                    break;
                case 6:
                    nomeQuestionario = 'Postulantes/Ingresso no Discipulado - CA';
                    break;
                case 7:
                    nomeQuestionario = 'Discipulos de Primeiro Ano - CA';
                    break;
                case 8:
                    nomeQuestionario = 'Renovação das Promessas - CA';
                    break;
                default:
                    nomeQuestionario = null;
                    break;
            }
        }
        return nomeQuestionario;
    };
    ParecerCelulaAutoridadeComponent.prototype.carregarQuestionarioMembro = function (idMembros) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.questionarioFormandoService.buscarPorMembrosId(idMembros)];
                    case 1:
                        response = _a.sent();
                        this.questionariosMembros = response;
                        return [3 /*break*/, 3];
                    case 2:
                        e_4 = _a.sent();
                        console.log(e_4);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCelulaAutoridadeComponent.prototype.carregarArquivoPDF = function (arquivoPDFNome) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var blobResponse, url;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.arquivoService
                            .obterDetalhesArquivoPDF(arquivoPDFNome)
                            .toPromise()];
                    case 1:
                        blobResponse = _a.sent();
                        url = URL.createObjectURL(blobResponse);
                        this.sanitizedPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
                        return [2 /*return*/];
                }
            });
        });
    };
    ParecerCelulaAutoridadeComponent.prototype.cadastrarConsideracaoConselhoLocal = function (membro) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var err_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, this.atualizarQuestionarioFormando(membro)];
                    case 1:
                        _a.sent();
                        /*await this.caminhoFormativoService.cadastrarConsideracaoConselhoLocal(
                          membro
                        ); */
                        return [4 /*yield*/, this.carregarMembrosCelula()];
                    case 2:
                        /*await this.caminhoFormativoService.cadastrarConsideracaoConselhoLocal(
                          membro
                        ); */
                        _a.sent();
                        this.swtAlert2Service.successAlert("Parecer de consideração do Conselho Local atualizado com sucesso!");
                        return [3 /*break*/, 4];
                    case 3:
                        err_2 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_2.error.errors);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCelulaAutoridadeComponent.prototype.atualizarQuestionarioFormando = function (membro) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var responseQuestionario, response, err_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        return [4 /*yield*/, this.questionarioFormandoService.buscarPorId(membro.idQuestionarioFormando)];
                    case 1:
                        responseQuestionario = _a.sent();
                        this.questionarioFormando = responseQuestionario;
                        if (!(this.questionarioFormando.id != null)) return [3 /*break*/, 3];
                        this.questionarioFormando.indicacaoCL = membro.indicacaoCL;
                        this.questionarioFormando.justificativaCL = membro.justificativaCL;
                        this.questionarioFormando.isDiscernimentoGG = membro.isDiscernimentoGG;
                        this.questionarioFormando.consideracaoConcorda = membro.consideracaoConcorda;
                        this.questionarioFormando.caminhoFormativoFeedback = membro.caminhoFormativoFeedback;
                        this.questionarioFormando.caminhoFormativoSugeridoFC = membro.caminhoFormativoSugeridoFC;
                        this.questionarioFormando.consideracaoCL = membro.consideracaoCL;
                        this.questionarioFormando.corStatusVocacionalId = membro.corStatusVocacionalId;
                        return [4 /*yield*/, this.questionarioFormandoService.atualizar(this.questionarioFormando.id, this.questionarioFormando)];
                    case 2:
                        response = _a.sent();
                        this.questionarioFormando = response;
                        this.swtAlert2Service.successAlert(response.message);
                        _a.label = 3;
                    case 3: return [3 /*break*/, 5];
                    case 4:
                        err_3 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_3.error.errors);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCelulaAutoridadeComponent.prototype.getPessoaImagem = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.imagemService.getPessoaPhoto(id)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.body];
                    case 2:
                        err_4 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_4.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCelulaAutoridadeComponent.prototype.cliqueNoBotaoListener = function (botao) {
        this.carregarMembrosCelula();
    };
    ParecerCelulaAutoridadeComponent.prototype.concordanciaConselhoLocal = function (membro) {
        if (membro.consideracaoConcorda === true) {
            membro.caminhoFormativoFeedback =
                membro.caminhoFormativoSugeridoFC;
            membro.consideracaoCL = "";
        }
        else {
            membro.caminhoFormativoFeedback = "";
        }
    };
    ParecerCelulaAutoridadeComponent.prototype.copiarTextoConsideracaoConselhoLocalParaFeedback = function (membro) {
        membro.caminhoFormativoFeedback = membro.consideracaoCL;
    };
    ParecerCelulaAutoridadeComponent.prototype.obterCorDaLinha = function (membro) {
        var cssObject = {};
        if (membro.corStatusVocacionalValor != null) {
            cssObject["background-color"] = membro.corStatusVocacionalValor;
        }
        if (membro.corStatusVocacionalNome != null &&
            membro.corStatusVocacionalNome.localeCompare("Desligamento") == 0) {
            cssObject["color"] = "#fff";
        }
        return cssObject;
    };
    ParecerCelulaAutoridadeComponent.prototype.truncateText = function (text, limit) {
        if (limit === void 0) { limit = 50; }
        if (!text) {
            return '';
        }
        return text.length > limit ? text.substring(0, limit) + '...' : text;
    };
    return ParecerCelulaAutoridadeComponent;
}());
export { ParecerCelulaAutoridadeComponent };
