import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
var ParecerCALAnswerDefaultViewPublicComponent = /** @class */ (function () {
    function ParecerCALAnswerDefaultViewPublicComponent(wopGetAllPublic, pessoaService, imagemService, paisService, estadoService, cidadeService, elementoGrupoDominioService, elementoGrupoDominioLocalService, missaoService, swtAlert2Service, usuarioService, pessoaBuilder, pessoaInfoBuilder, dadoVocacionalService, dadoFormandoCAService, caminhoFormativoService, respostaParecerService, questionarioFormandoService, parecerResponsavelService, dadoFormandoCABuilder, caminhoFormativoFormandoBuilder, respostaParecerBuilder, questionarioFormandoBuilder, route, router) {
        this.wopGetAllPublic = wopGetAllPublic;
        this.pessoaService = pessoaService;
        this.imagemService = imagemService;
        this.paisService = paisService;
        this.estadoService = estadoService;
        this.cidadeService = cidadeService;
        this.elementoGrupoDominioService = elementoGrupoDominioService;
        this.elementoGrupoDominioLocalService = elementoGrupoDominioLocalService;
        this.missaoService = missaoService;
        this.swtAlert2Service = swtAlert2Service;
        this.usuarioService = usuarioService;
        this.pessoaBuilder = pessoaBuilder;
        this.pessoaInfoBuilder = pessoaInfoBuilder;
        this.dadoVocacionalService = dadoVocacionalService;
        this.dadoFormandoCAService = dadoFormandoCAService;
        this.caminhoFormativoService = caminhoFormativoService;
        this.respostaParecerService = respostaParecerService;
        this.questionarioFormandoService = questionarioFormandoService;
        this.parecerResponsavelService = parecerResponsavelService;
        this.dadoFormandoCABuilder = dadoFormandoCABuilder;
        this.caminhoFormativoFormandoBuilder = caminhoFormativoFormandoBuilder;
        this.respostaParecerBuilder = respostaParecerBuilder;
        this.questionarioFormandoBuilder = questionarioFormandoBuilder;
        this.route = route;
        this.router = router;
        this.intlTelInputCalled = false;
        this.missoesVinculo = new Array();
        this.missoes = new Array();
        this.dadoFormandoCA = this.dadoFormandoCABuilder.getInstance();
        this.caminhoFormativoFormando =
            this.caminhoFormativoFormandoBuilder.getInstance();
        this.imageChangedEvent = "";
        this.croppedImage = "";
        this.nextClicked = false;
        this.pessoaResponsavel = this.pessoaBuilder.getInstance();
        this.formando = this.pessoaBuilder.getInstance();
        this.urlBaseImagem = environment.imagem.urlBaseImagem;
        this.url = environment.usuario.selectPorUsername;
        this.questionarioFormando = this.questionarioFormandoBuilder.getInstance();
        this.resposta36 = respostaParecerBuilder.getInstance();
        this.resposta37 = respostaParecerBuilder.getInstance();
        this.resposta38 = respostaParecerBuilder.getInstance();
        this.resposta39 = respostaParecerBuilder.getInstance();
        this.resposta40 = respostaParecerBuilder.getInstance();
        this.resposta41 = respostaParecerBuilder.getInstance();
        this.resposta42 = respostaParecerBuilder.getInstance();
        this.resposta43 = respostaParecerBuilder.getInstance();
        this.resposta44 = respostaParecerBuilder.getInstance();
        this.resposta45 = respostaParecerBuilder.getInstance();
        this.resposta46 = respostaParecerBuilder.getInstance();
        this.resposta47 = respostaParecerBuilder.getInstance();
        this.resposta48 = respostaParecerBuilder.getInstance();
        this.resposta49 = respostaParecerBuilder.getInstance();
        this.resposta50 = respostaParecerBuilder.getInstance();
        this.resposta51 = respostaParecerBuilder.getInstance();
        this.resposta52 = respostaParecerBuilder.getInstance();
        this.resposta53 = respostaParecerBuilder.getInstance();
        this.resposta54 = respostaParecerBuilder.getInstance();
        this.resposta55 = respostaParecerBuilder.getInstance();
        this.resposta56 = respostaParecerBuilder.getInstance();
        this.resposta57 = respostaParecerBuilder.getInstance();
        this.resposta58 = respostaParecerBuilder.getInstance();
        this.resposta59 = respostaParecerBuilder.getInstance();
        this.resposta60 = respostaParecerBuilder.getInstance();
        this.resposta61 = respostaParecerBuilder.getInstance();
        this.resposta62 = respostaParecerBuilder.getInstance();
        this.resposta63 = respostaParecerBuilder.getInstance();
        this.resposta64 = respostaParecerBuilder.getInstance();
        this.resposta65 = respostaParecerBuilder.getInstance();
        this.resposta66 = respostaParecerBuilder.getInstance();
        this.resposta67 = respostaParecerBuilder.getInstance();
        this.resposta68 = respostaParecerBuilder.getInstance();
        this.resposta69 = respostaParecerBuilder.getInstance();
        this.resposta70 = respostaParecerBuilder.getInstance();
        this.resposta71 = respostaParecerBuilder.getInstance();
        this.resposta72 = respostaParecerBuilder.getInstance();
        this.resposta73 = respostaParecerBuilder.getInstance();
        this.resposta74 = respostaParecerBuilder.getInstance();
        this.resposta75 = respostaParecerBuilder.getInstance();
        this.resposta76 = respostaParecerBuilder.getInstance();
        this.resposta77 = respostaParecerBuilder.getInstance();
    }
    ParecerCALAnswerDefaultViewPublicComponent.prototype.ngOnInit = function () {
        this.initialization();
    };
    ParecerCALAnswerDefaultViewPublicComponent.prototype.imprimirValorDoForm = function () {
        console.log("Valor do Form", JSON.stringify(this.dadosPessoaisForm.value));
    };
    ParecerCALAnswerDefaultViewPublicComponent.prototype.initialization = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        //await this.carregarMissoesVinculo();
                        this.parecerResponsavelId = Number(this.route.snapshot.paramMap.get("id"));
                        return [4 /*yield*/, this.carregarParecerResponsavel()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.carregarQuestionarioFormando()];
                    case 2:
                        _a.sent();
                        /*await this.carregarPessoaFormando(this.questionarioFormando.pessoaId);
                        await this.carregarDadoVocacional();
                        await this.carregarDadoFormandoCA();
                        await this.carregarCaminhoFormativo();*/
                        return [4 /*yield*/, this.carregarRespostas()];
                    case 3:
                        /*await this.carregarPessoaFormando(this.questionarioFormando.pessoaId);
                        await this.carregarDadoVocacional();
                        await this.carregarDadoFormandoCA();
                        await this.carregarCaminhoFormativo();*/
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ParecerCALAnswerDefaultViewPublicComponent.prototype.carregarMissoesVinculo = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.wopGetAllPublic.getAllMissao()];
                    case 1:
                        response = _a.sent();
                        this.missoesVinculo = response.entity;
                        this.missoesVinculo = this.missoesVinculo.sort(function (a, b) {
                            return a.nome.localeCompare(b.nome);
                        });
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_1.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCALAnswerDefaultViewPublicComponent.prototype.carregarPessoaFormando = function (pessoaId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, blogImage, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        return [4 /*yield*/, this.pessoaService.buscaPorId(pessoaId)];
                    case 1:
                        response = _a.sent();
                        this.formando = response.entity;
                        this.pessoaFormandoId = this.formando.id;
                        if (!(this.formando.imagemId != null)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.getPessoaImagem(this.formando.imagemId)];
                    case 2:
                        blogImage = _a.sent();
                        this.imagemService.createImage(blogImage, this.formando);
                        _a.label = 3;
                    case 3: return [3 /*break*/, 5];
                    case 4:
                        err_1 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_1.error.errors);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCALAnswerDefaultViewPublicComponent.prototype.carregarPessoaResponsavel = function (usuarioId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.pessoaService.buscaPorUsuarioId(usuarioId)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.entity];
                    case 2:
                        err_2 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_2.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCALAnswerDefaultViewPublicComponent.prototype.logOnConsole = function (dadosPessoaisForm) {
        console.log(dadosPessoaisForm);
    };
    ParecerCALAnswerDefaultViewPublicComponent.prototype.activateTab = function (tab) {
        $('.nav-tabs a[href="#' + tab + '"]').removeClass("disabled");
        $('.nav-tabs a[href="#' + tab + '"]').tab("show");
    };
    ParecerCALAnswerDefaultViewPublicComponent.prototype.activateTab2 = function (action) {
        if (action.localeCompare("previous") == 0) {
            if (this.activeTabNumber > 0) {
                this.activeTabNumber -= 1;
            }
        }
        else if (action.localeCompare("next") == 0) {
            if (this.activeTabNumber < this.tabNames.length - 1) {
                this.activeTabNumber += 1;
            }
        }
        $('.nav-tabs a[href="#' + this.tabNames[this.activeTabNumber] + '"]').removeClass("disabled");
        $('.nav-tabs a[href="#' + this.tabNames[this.activeTabNumber] + '"]').tab("show");
    };
    ParecerCALAnswerDefaultViewPublicComponent.prototype.fileChangeEvent = function (event) {
        this.imageChangedEvent = event;
        console.log(event);
    };
    ParecerCALAnswerDefaultViewPublicComponent.prototype.imageCropped = function (event) {
        this.croppedImage = event.base64;
    };
    ParecerCALAnswerDefaultViewPublicComponent.prototype.imageLoaded = function () {
        console.log("Imagem carregada");
    };
    ParecerCALAnswerDefaultViewPublicComponent.prototype.cropperReady = function () {
        console.log("Imagem cortada");
    };
    ParecerCALAnswerDefaultViewPublicComponent.prototype.loadImageFailed = function () {
        console.log("Carregamento da imagem falhou!");
    };
    ParecerCALAnswerDefaultViewPublicComponent.prototype.carregarDadoVocacional = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.dadoVocacionalService.porPessoaId(this.formando.id)];
                    case 1:
                        response = _a.sent();
                        if (response.entity != undefined && response.entity != null) {
                            this.dadoVocacional = response.entity;
                            this.pessoaFormadorPessoalSelecionado =
                                this.pessoaInfoBuilder.getInstance();
                            this.pessoaFormadorPessoalSelecionado.nome =
                                this.dadoVocacional.formadorPessoalPessoaNome;
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_3 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_3.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCALAnswerDefaultViewPublicComponent.prototype.selecionarFormadorComunitario = function (pessoa) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/];
            });
        });
    };
    ParecerCALAnswerDefaultViewPublicComponent.prototype.getPessoaImagem = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.imagemService.getPessoaPhoto(id)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.body];
                    case 2:
                        err_4 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_4.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCALAnswerDefaultViewPublicComponent.prototype.carregarDadoFormandoCA = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.dadoFormandoCAService.porPessoaId(this.formando.id)];
                    case 1:
                        response = _a.sent();
                        if (response != null && response != undefined) {
                            this.dadoFormandoCA = response;
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_5 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_5.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCALAnswerDefaultViewPublicComponent.prototype.carregarCaminhoFormativo = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_6;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.caminhoFormativoService.porPessoaId(this.formando.id)];
                    case 1:
                        response = _a.sent();
                        if (response != null && response != undefined) {
                            this.caminhoFormativoFormando = response;
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_6 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_6.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCALAnswerDefaultViewPublicComponent.prototype.atualizarCaminhoFormativo = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_7;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        if (!(this.caminhoFormativoFormando.id != null)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.caminhoFormativoService.atualizar(this.caminhoFormativoFormando)];
                    case 1:
                        response = _a.sent();
                        this.caminhoFormativoFormando = response;
                        this.swtAlert2Service.successAlert(response.message);
                        _a.label = 2;
                    case 2: return [3 /*break*/, 4];
                    case 3:
                        err_7 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_7.error.errors);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCALAnswerDefaultViewPublicComponent.prototype.carregarRespostas = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_8;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.respostaParecerService.buscaPublicaPorPessoaIdEParecerId(this.questionarioFormando.pessoaId, this.parecerResponsavel.parecer_id)];
                    case 1:
                        response = _a.sent();
                        if (response != null && response != undefined) {
                            this.respostas = response;
                            console.log(this.respostas);
                            this.respostas.forEach(function (resposta) {
                                switch (resposta.parecer_pergunta_id) {
                                    case 36:
                                        _this.resposta36 = resposta;
                                        break;
                                    case 37:
                                        _this.resposta37 = resposta;
                                        break;
                                    case 38:
                                        _this.resposta38 = resposta;
                                        break;
                                    case 39:
                                        _this.resposta39 = resposta;
                                        break;
                                    case 40:
                                        _this.resposta40 = resposta;
                                        break;
                                    case 41:
                                        _this.resposta41 = resposta;
                                        break;
                                    case 42:
                                        _this.resposta42 = resposta;
                                        break;
                                    case 43:
                                        _this.resposta43 = resposta;
                                        break;
                                    case 44:
                                        _this.resposta44 = resposta;
                                        break;
                                    case 45:
                                        _this.resposta45 = resposta;
                                        break;
                                    case 46:
                                        _this.resposta46 = resposta;
                                        break;
                                    case 47:
                                        _this.resposta47 = resposta;
                                        break;
                                    case 48:
                                        _this.resposta48 = resposta;
                                        break;
                                    case 49:
                                        _this.resposta49 = resposta;
                                        break;
                                    case 50:
                                        _this.resposta50 = resposta;
                                        break;
                                    case 51:
                                        _this.resposta51 = resposta;
                                        break;
                                    case 52:
                                        _this.resposta52 = resposta;
                                        break;
                                    case 53:
                                        _this.resposta53 = resposta;
                                        break;
                                    case 54:
                                        _this.resposta54 = resposta;
                                        break;
                                    case 55:
                                        _this.resposta55 = resposta;
                                        break;
                                    case 56:
                                        _this.resposta56 = resposta;
                                        break;
                                    case 57:
                                        _this.resposta57 = resposta;
                                        break;
                                    case 58:
                                        _this.resposta58 = resposta;
                                        break;
                                    case 59:
                                        _this.resposta59 = resposta;
                                        break;
                                    case 60:
                                        _this.resposta60 = resposta;
                                        break;
                                    case 61:
                                        _this.resposta61 = resposta;
                                        break;
                                    case 62:
                                        _this.resposta62 = resposta;
                                        break;
                                    case 63:
                                        _this.resposta63 = resposta;
                                        break;
                                    case 64:
                                        _this.resposta64 = resposta;
                                        break;
                                    case 65:
                                        _this.resposta65 = resposta;
                                        break;
                                    case 66:
                                        _this.resposta66 = resposta;
                                        break;
                                    case 67:
                                        _this.resposta67 = resposta;
                                        break;
                                    case 68:
                                        _this.resposta68 = resposta;
                                        break;
                                    case 69:
                                        _this.resposta69 = resposta;
                                        break;
                                    case 70:
                                        _this.resposta70 = resposta;
                                        break;
                                    case 71:
                                        _this.resposta71 = resposta;
                                        break;
                                    case 72:
                                        _this.resposta72 = resposta;
                                        break;
                                    case 73:
                                        _this.resposta73 = resposta;
                                        break;
                                    case 74:
                                        _this.resposta74 = resposta;
                                        break;
                                    case 75:
                                        _this.resposta75 = resposta;
                                        break;
                                    case 76:
                                        _this.resposta76 = resposta;
                                        break;
                                    case 77:
                                        _this.resposta77 = resposta;
                                        break;
                                }
                            });
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_8 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_8.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCALAnswerDefaultViewPublicComponent.prototype.carregarParecerResponsavel = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_9;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.parecerResponsavelService.buscaPublicaPorId(this.parecerResponsavelId)];
                    case 1:
                        response = _a.sent();
                        if (response != null && response != undefined) {
                            this.parecerResponsavel = response;
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_9 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_9.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCALAnswerDefaultViewPublicComponent.prototype.carregarQuestionarioFormando = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_10;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.questionarioFormandoService.buscaPublicaPorId(this.parecerResponsavel.questionarioFormandoId)];
                    case 1:
                        response = _a.sent();
                        if (response != null && response != undefined) {
                            this.questionarioFormando = response;
                            switch (this.questionarioFormando.questionarioId) {
                                case 4:
                                    this.questionarioNome = 'Pedido para as Primeiras Promessas - CA';
                                    break;
                                case 5:
                                    this.questionarioNome = 'Pedido para as Promessas Definitivas - CA';
                                    break;
                                case 6:
                                    this.questionarioNome = 'Postulantes/Ingresso no Discipulado - CA';
                                    break;
                                case 7:
                                    this.questionarioNome = 'Discipulos de Primeiro Ano - CA';
                                    break;
                                case 8:
                                    this.questionarioNome = 'Renovação das Promessas - CA';
                                    break;
                                default:
                                    this.questionarioNome = null;
                                    break;
                            }
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_10 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_10.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCALAnswerDefaultViewPublicComponent.prototype.respostaVazia = function (resposta) {
        return (resposta.resposta == null ||
            resposta.resposta == undefined ||
            resposta.resposta == "");
    };
    return ParecerCALAnswerDefaultViewPublicComponent;
}());
export { ParecerCALAnswerDefaultViewPublicComponent };
