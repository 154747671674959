import * as tslib_1 from "tslib";
import { GenericService } from 'src/app/core/services/generic.service';
import { environment } from 'src/environments/environment';
var QuestionarioService = /** @class */ (function (_super) {
    tslib_1.__extends(QuestionarioService, _super);
    function QuestionarioService(http, router) {
        var _this = _super.call(this, http, router) || this;
        _this.http = http;
        _this.router = router;
        _this.setEntityType('questionario');
        return _this;
    }
    QuestionarioService.prototype.porPessoaId = function (id) {
        return this.http.get(this.apiURL + "/porpessoa/" + id, { 'headers': this.getHeaders() }).toPromise();
    };
    QuestionarioService.prototype.salvarQuestionario = function (questionario) {
        return this.http.post(this.apiURL, questionario);
    };
    QuestionarioService.prototype.getQuestionario = function (questionarioId) {
        var url = this.apiURL + "/" + questionarioId;
        return this.http.get(url);
    };
    QuestionarioService.prototype.buscarAbertos = function () {
        return this.http
            .get(environment.moduloFormacao.urlModuloFormacao + "questionario/aberta", { headers: this.getHeaders() })
            .toPromise();
    };
    return QuestionarioService;
}(GenericService));
export { QuestionarioService };
