import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
var ParecerCALFCAnswerDefaultComponent = /** @class */ (function () {
    function ParecerCALFCAnswerDefaultComponent(wopGetAllPublic, pessoaService, imagemService, paisService, estadoService, cidadeService, elementoGrupoDominioService, elementoGrupoDominioLocalService, missaoService, swtAlert2Service, usuarioService, pessoaBuilder, pessoaInfoBuilder, dadoVocacionalService, dadoFormandoCAService, caminhoFormativoService, respostaParecerService, questionarioFormandoService, parecerResponsavelService, dadoFormandoCABuilder, caminhoFormativoFormandoBuilder, respostaParecerBuilder, questionarioFormandoBuilder, route, router) {
        this.wopGetAllPublic = wopGetAllPublic;
        this.pessoaService = pessoaService;
        this.imagemService = imagemService;
        this.paisService = paisService;
        this.estadoService = estadoService;
        this.cidadeService = cidadeService;
        this.elementoGrupoDominioService = elementoGrupoDominioService;
        this.elementoGrupoDominioLocalService = elementoGrupoDominioLocalService;
        this.missaoService = missaoService;
        this.swtAlert2Service = swtAlert2Service;
        this.usuarioService = usuarioService;
        this.pessoaBuilder = pessoaBuilder;
        this.pessoaInfoBuilder = pessoaInfoBuilder;
        this.dadoVocacionalService = dadoVocacionalService;
        this.dadoFormandoCAService = dadoFormandoCAService;
        this.caminhoFormativoService = caminhoFormativoService;
        this.respostaParecerService = respostaParecerService;
        this.questionarioFormandoService = questionarioFormandoService;
        this.parecerResponsavelService = parecerResponsavelService;
        this.dadoFormandoCABuilder = dadoFormandoCABuilder;
        this.caminhoFormativoFormandoBuilder = caminhoFormativoFormandoBuilder;
        this.respostaParecerBuilder = respostaParecerBuilder;
        this.questionarioFormandoBuilder = questionarioFormandoBuilder;
        this.route = route;
        this.router = router;
        this.intlTelInputCalled = false;
        this.missoesVinculo = new Array();
        this.missoes = new Array();
        this.formasVida = new Array();
        this.niveisFormacao = new Array();
        this.dadoFormandoCA = this.dadoFormandoCABuilder.getInstance();
        this.caminhoFormativoFormando =
            this.caminhoFormativoFormandoBuilder.getInstance();
        this.imageChangedEvent = "";
        this.croppedImage = "";
        this.nextClicked = false;
        this.pessoaResponsavel = this.pessoaBuilder.getInstance();
        this.formando = this.pessoaBuilder.getInstance();
        this.urlBaseImagem = environment.imagem.urlBaseImagem;
        this.url = environment.usuario.selectPorUsername;
        this.questionarioFormando = this.questionarioFormandoBuilder.getInstance();
        this.tabNames = ["dados-pessoais-tab-content", "questionario-tab-content"];
        this.activeTabNumber = 0;
        this.resposta36 = respostaParecerBuilder.getInstance();
        this.resposta37 = respostaParecerBuilder.getInstance();
        this.resposta38 = respostaParecerBuilder.getInstance();
        this.resposta39 = respostaParecerBuilder.getInstance();
        this.resposta40 = respostaParecerBuilder.getInstance();
        this.resposta41 = respostaParecerBuilder.getInstance();
        this.resposta42 = respostaParecerBuilder.getInstance();
        this.resposta43 = respostaParecerBuilder.getInstance();
        this.resposta44 = respostaParecerBuilder.getInstance();
        this.resposta45 = respostaParecerBuilder.getInstance();
        this.resposta46 = respostaParecerBuilder.getInstance();
        this.resposta47 = respostaParecerBuilder.getInstance();
        this.resposta48 = respostaParecerBuilder.getInstance();
        this.resposta49 = respostaParecerBuilder.getInstance();
        this.resposta50 = respostaParecerBuilder.getInstance();
        this.resposta51 = respostaParecerBuilder.getInstance();
        this.resposta52 = respostaParecerBuilder.getInstance();
        this.resposta53 = respostaParecerBuilder.getInstance();
        this.resposta54 = respostaParecerBuilder.getInstance();
        this.resposta55 = respostaParecerBuilder.getInstance();
        this.resposta56 = respostaParecerBuilder.getInstance();
        this.resposta57 = respostaParecerBuilder.getInstance();
        this.resposta58 = respostaParecerBuilder.getInstance();
        this.resposta59 = respostaParecerBuilder.getInstance();
        this.resposta60 = respostaParecerBuilder.getInstance();
        this.resposta61 = respostaParecerBuilder.getInstance();
        this.resposta62 = respostaParecerBuilder.getInstance();
        this.resposta63 = respostaParecerBuilder.getInstance();
        this.resposta64 = respostaParecerBuilder.getInstance();
        this.resposta65 = respostaParecerBuilder.getInstance();
        this.resposta66 = respostaParecerBuilder.getInstance();
        this.resposta67 = respostaParecerBuilder.getInstance();
        this.resposta68 = respostaParecerBuilder.getInstance();
        this.resposta69 = respostaParecerBuilder.getInstance();
        this.resposta70 = respostaParecerBuilder.getInstance();
        this.resposta71 = respostaParecerBuilder.getInstance();
        this.resposta72 = respostaParecerBuilder.getInstance();
        this.resposta73 = respostaParecerBuilder.getInstance();
        this.resposta74 = respostaParecerBuilder.getInstance();
        this.resposta75 = respostaParecerBuilder.getInstance();
        this.resposta76 = respostaParecerBuilder.getInstance();
        this.resposta77 = respostaParecerBuilder.getInstance();
    }
    ParecerCALFCAnswerDefaultComponent.prototype.ngOnInit = function () {
        this.initialization();
    };
    ParecerCALFCAnswerDefaultComponent.prototype.imprimirValorDoForm = function () {
        console.log("Valor do Form", JSON.stringify(this.dadosPessoaisForm.value));
    };
    ParecerCALFCAnswerDefaultComponent.prototype.initialization = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.getUsuarioAtual()];
                    case 1:
                        _b.sent();
                        this.carregarFormasVida();
                        this.carregarNiveisFormacao();
                        return [4 /*yield*/, this.carregarMissoesVinculo()];
                    case 2:
                        _b.sent();
                        this.parecerResponsavelId = Number(this.route.snapshot.paramMap.get("id"));
                        return [4 /*yield*/, this.carregarParecerResponsavel()];
                    case 3:
                        _b.sent();
                        return [4 /*yield*/, this.carregarQuestionarioFormando()];
                    case 4:
                        _b.sent();
                        return [4 /*yield*/, this.carregarPessoaFormando(this.questionarioFormando.pessoaId)];
                    case 5:
                        _b.sent();
                        _a = this;
                        return [4 /*yield*/, this.carregarPessoaResponsavel(this.usuarioAtual.id)];
                    case 6:
                        _a.pessoaResponsavel = _b.sent();
                        return [4 /*yield*/, this.carregarDadoVocacional()];
                    case 7:
                        _b.sent();
                        return [4 /*yield*/, this.carregarDadoFormandoCA()];
                    case 8:
                        _b.sent();
                        return [4 /*yield*/, this.carregarCaminhoFormativo()];
                    case 9:
                        _b.sent();
                        return [4 /*yield*/, this.carregarRespostas()];
                    case 10:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ParecerCALFCAnswerDefaultComponent.prototype.getUsuarioAtual = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.usuarioService.getCurrentUser()];
                    case 1:
                        response = _a.sent();
                        this.usuarioAtual = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        console.log(e_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCALFCAnswerDefaultComponent.prototype.carregarMissoesVinculo = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.wopGetAllPublic.getAllMissao()];
                    case 1:
                        response = _a.sent();
                        this.missoesVinculo = response.entity;
                        this.missoesVinculo = this.missoesVinculo.sort(function (a, b) {
                            return a.nome.localeCompare(b.nome);
                        });
                        return [3 /*break*/, 3];
                    case 2:
                        e_2 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_2.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCALFCAnswerDefaultComponent.prototype.carregarPessoaFormando = function (pessoaId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, blogImage, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        return [4 /*yield*/, this.pessoaService.buscaPorId(pessoaId)];
                    case 1:
                        response = _a.sent();
                        this.formando = response.entity;
                        this.pessoaFormandoId = this.formando.id;
                        if (!(this.formando.imagemId != null)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.getPessoaImagem(this.formando.imagemId)];
                    case 2:
                        blogImage = _a.sent();
                        this.imagemService.createImage(blogImage, this.formando);
                        _a.label = 3;
                    case 3: return [3 /*break*/, 5];
                    case 4:
                        err_1 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_1.error.errors);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCALFCAnswerDefaultComponent.prototype.carregarPessoaResponsavel = function (usuarioId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.pessoaService.buscaPorUsuarioId(usuarioId)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.entity];
                    case 2:
                        err_2 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_2.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCALFCAnswerDefaultComponent.prototype.carregarFormasVida = function () {
        var _this = this;
        var buscaFormaVida = {
            grupoNome: "FORMA_VIDA",
        };
        this.elementoGrupoDominioService
            .buscarPorGrupoDominioNome(buscaFormaVida)
            .then(function (lista) {
            _this.formasVida = lista.entity;
        })
            .catch(function (err) {
            _this.swtAlert2Service.errorAlert(err.error.errors);
        });
    };
    ParecerCALFCAnswerDefaultComponent.prototype.carregarNiveisFormacao = function () {
        var _this = this;
        var busca = {
            grupoNome: "NIVEL_FORMACAO",
        };
        this.elementoGrupoDominioService
            .buscarPorGrupoDominioNome(busca)
            .then(function (lista) {
            _this.niveisFormacao = lista.entity;
        })
            .catch(function (err) {
            _this.swtAlert2Service.errorAlert(err.error.errors);
        });
    };
    ParecerCALFCAnswerDefaultComponent.prototype.carregarPaises = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.paisService.findAll()];
                    case 1:
                        response = _a.sent();
                        this.paises = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        e_3 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_3.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCALFCAnswerDefaultComponent.prototype.carregarEstados = function (paisId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var request, response, e_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        request = {
                            paisId: paisId,
                            nome: undefined,
                            pageNumber: 0,
                            quantityOfElements: 1000,
                        };
                        return [4 /*yield*/, this.estadoService.buscarEstados(request)];
                    case 1:
                        response = _a.sent();
                        this.estados = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        e_4 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_4.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCALFCAnswerDefaultComponent.prototype.carregarCidades = function (estadoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var request, response, e_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        request = {
                            estadoId: estadoId,
                            nome: undefined,
                            pageNumber: 0,
                            quantityOfElements: 1000,
                        };
                        return [4 /*yield*/, this.cidadeService.buscarCidades(request)];
                    case 1:
                        response = _a.sent();
                        this.cidades = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        e_5 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_5.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCALFCAnswerDefaultComponent.prototype.logOnConsole = function (dadosPessoaisForm) {
        console.log(dadosPessoaisForm);
    };
    ParecerCALFCAnswerDefaultComponent.prototype.activateTab = function (tab) {
        $('.nav-tabs a[href="#' + tab + '"]').removeClass("disabled");
        $('.nav-tabs a[href="#' + tab + '"]').tab("show");
    };
    ParecerCALFCAnswerDefaultComponent.prototype.activateTab2 = function (action) {
        if (action.localeCompare("previous") == 0) {
            if (this.activeTabNumber > 0) {
                this.activeTabNumber -= 1;
            }
        }
        else if (action.localeCompare("next") == 0) {
            if (this.activeTabNumber < this.tabNames.length - 1) {
                this.activeTabNumber += 1;
            }
        }
        $('.nav-tabs a[href="#' + this.tabNames[this.activeTabNumber] + '"]').removeClass("disabled");
        $('.nav-tabs a[href="#' + this.tabNames[this.activeTabNumber] + '"]').tab("show");
    };
    ParecerCALFCAnswerDefaultComponent.prototype.salvar = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.atualizarCaminhoFormativo();
                        return [4 /*yield*/, this.atualizarParecerResponsavel("INICIADO")];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ParecerCALFCAnswerDefaultComponent.prototype.fileChangeEvent = function (event) {
        this.imageChangedEvent = event;
        console.log(event);
    };
    ParecerCALFCAnswerDefaultComponent.prototype.imageCropped = function (event) {
        this.croppedImage = event.base64;
    };
    ParecerCALFCAnswerDefaultComponent.prototype.imageLoaded = function () {
        console.log("Imagem carregada");
    };
    ParecerCALFCAnswerDefaultComponent.prototype.cropperReady = function () {
        console.log("Imagem cortada");
    };
    ParecerCALFCAnswerDefaultComponent.prototype.loadImageFailed = function () {
        console.log("Carregamento da imagem falhou!");
    };
    ParecerCALFCAnswerDefaultComponent.prototype.carregarDadoVocacional = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.dadoVocacionalService.porPessoaId(this.formando.id)];
                    case 1:
                        response = _a.sent();
                        if (response.entity != undefined && response.entity != null) {
                            this.dadoVocacional = response.entity;
                            this.pessoaFormadorPessoalSelecionado =
                                this.pessoaInfoBuilder.getInstance();
                            this.pessoaFormadorPessoalSelecionado.nome =
                                this.dadoVocacional.formadorPessoalPessoaNome;
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_3 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_3.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCALFCAnswerDefaultComponent.prototype.selecionarFormadorComunitario = function (pessoa) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/];
            });
        });
    };
    ParecerCALFCAnswerDefaultComponent.prototype.getPessoaImagem = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.imagemService.getPessoaPhoto(id)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.body];
                    case 2:
                        err_4 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_4.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCALFCAnswerDefaultComponent.prototype.carregarDadoFormandoCA = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.dadoFormandoCAService.porPessoaId(this.formando.id)];
                    case 1:
                        response = _a.sent();
                        if (response != null && response != undefined) {
                            this.dadoFormandoCA = response;
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_5 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_5.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCALFCAnswerDefaultComponent.prototype.carregarCaminhoFormativo = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_6;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.caminhoFormativoService.porPessoaId(this.formando.id)];
                    case 1:
                        response = _a.sent();
                        if (response != null && response != undefined) {
                            this.caminhoFormativoFormando = response;
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_6 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_6.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCALFCAnswerDefaultComponent.prototype.atualizarCaminhoFormativo = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_7;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        if (!(this.caminhoFormativoFormando.id != null)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.caminhoFormativoService.atualizar(this.caminhoFormativoFormando)];
                    case 1:
                        response = _a.sent();
                        this.caminhoFormativoFormando = response;
                        this.swtAlert2Service.successAlert(response.message);
                        _a.label = 2;
                    case 2: return [3 /*break*/, 4];
                    case 3:
                        err_7 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_7.error.errors);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCALFCAnswerDefaultComponent.prototype.atualizarQuestionarioFormando = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_8;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        if (!(this.questionarioFormando.id != null)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.questionarioFormandoService.atualizar(this.questionarioFormando.id, this.questionarioFormando)];
                    case 1:
                        response = _a.sent();
                        this.questionarioFormando = response;
                        this.swtAlert2Service.successAlert(response.message);
                        _a.label = 2;
                    case 2: return [3 /*break*/, 4];
                    case 3:
                        err_8 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_8.error.errors);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCALFCAnswerDefaultComponent.prototype.carregarRespostas = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_9;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.respostaParecerService.porPessoaIdEParecerId(this.pessoaFormandoId, this.parecerResponsavel.parecer_id)];
                    case 1:
                        response = _a.sent();
                        if (response != null && response != undefined) {
                            this.respostas = response;
                            this.respostas.forEach(function (resposta) {
                                switch (resposta.parecer_pergunta_id) {
                                    case 36:
                                        _this.resposta36 = resposta;
                                        break;
                                    case 37:
                                        _this.resposta37 = resposta;
                                        break;
                                    case 38:
                                        _this.resposta38 = resposta;
                                        break;
                                    case 39:
                                        _this.resposta39 = resposta;
                                        break;
                                    case 40:
                                        _this.resposta40 = resposta;
                                        break;
                                    case 41:
                                        _this.resposta41 = resposta;
                                        break;
                                    case 42:
                                        _this.resposta42 = resposta;
                                        break;
                                    case 43:
                                        _this.resposta43 = resposta;
                                        break;
                                    case 44:
                                        _this.resposta44 = resposta;
                                        break;
                                    case 45:
                                        _this.resposta45 = resposta;
                                        break;
                                    case 46:
                                        _this.resposta46 = resposta;
                                        break;
                                    case 47:
                                        _this.resposta47 = resposta;
                                        break;
                                    case 48:
                                        _this.resposta48 = resposta;
                                        break;
                                    case 49:
                                        _this.resposta49 = resposta;
                                        break;
                                    case 50:
                                        _this.resposta50 = resposta;
                                        break;
                                    case 51:
                                        _this.resposta51 = resposta;
                                        break;
                                    case 52:
                                        _this.resposta52 = resposta;
                                        break;
                                    case 53:
                                        _this.resposta53 = resposta;
                                        break;
                                    case 54:
                                        _this.resposta54 = resposta;
                                        break;
                                    case 55:
                                        _this.resposta55 = resposta;
                                        break;
                                    case 56:
                                        _this.resposta56 = resposta;
                                        break;
                                    case 57:
                                        _this.resposta57 = resposta;
                                        break;
                                    case 58:
                                        _this.resposta58 = resposta;
                                        break;
                                    case 59:
                                        _this.resposta59 = resposta;
                                        break;
                                    case 60:
                                        _this.resposta60 = resposta;
                                        break;
                                    case 61:
                                        _this.resposta61 = resposta;
                                        break;
                                    case 62:
                                        _this.resposta62 = resposta;
                                        break;
                                    case 63:
                                        _this.resposta63 = resposta;
                                        break;
                                    case 64:
                                        _this.resposta64 = resposta;
                                        break;
                                    case 65:
                                        _this.resposta65 = resposta;
                                        break;
                                    case 66:
                                        _this.resposta66 = resposta;
                                        break;
                                    case 67:
                                        _this.resposta67 = resposta;
                                        break;
                                    case 68:
                                        _this.resposta68 = resposta;
                                        break;
                                    case 69:
                                        _this.resposta69 = resposta;
                                        break;
                                    case 70:
                                        _this.resposta70 = resposta;
                                        break;
                                    case 71:
                                        _this.resposta71 = resposta;
                                        break;
                                    case 72:
                                        _this.resposta72 = resposta;
                                        break;
                                    case 73:
                                        _this.resposta73 = resposta;
                                        break;
                                    case 74:
                                        _this.resposta74 = resposta;
                                        break;
                                    case 75:
                                        _this.resposta75 = resposta;
                                        break;
                                    case 76:
                                        _this.resposta76 = resposta;
                                        break;
                                    case 77:
                                        _this.resposta77 = resposta;
                                        break;
                                }
                            });
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_9 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_9.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCALFCAnswerDefaultComponent.prototype.carregarParecerResponsavel = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_10;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.parecerResponsavelService.buscarPorId(this.parecerResponsavelId)];
                    case 1:
                        response = _a.sent();
                        if (response != null && response != undefined) {
                            this.parecerResponsavel = response;
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_10 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_10.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCALFCAnswerDefaultComponent.prototype.carregarQuestionarioFormando = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_11;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.questionarioFormandoService.buscarPorId(this.parecerResponsavel.questionarioFormandoId)];
                    case 1:
                        response = _a.sent();
                        if (response != null && response != undefined) {
                            this.questionarioFormando = response;
                            switch (this.questionarioFormando.questionarioId) {
                                case 4:
                                    this.questionarioNome = 'Pedido para as Primeiras Promessas - CA';
                                    break;
                                case 5:
                                    this.questionarioNome = 'Pedido para as Promessas Definitivas - CA';
                                    break;
                                case 6:
                                    this.questionarioNome = 'Postulantes/Ingresso no Discipulado - CA';
                                    break;
                                case 7:
                                    this.questionarioNome = 'Discipulos de Primeiro Ano - CA';
                                    break;
                                case 8:
                                    this.questionarioNome = 'Renovação das Promessas - CA';
                                    break;
                                default:
                                    this.questionarioNome = null;
                                    break;
                            }
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_11 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_11.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCALFCAnswerDefaultComponent.prototype.atualizarResposta = function (resposta) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, response, err_12;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        resposta.pessoaId = this.pessoaFormandoId;
                        if (!(resposta.id == null)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.respostaParecerService.cadastrar(resposta)];
                    case 1:
                        response = _a.sent();
                        this.swtAlert2Service.successAlert(response.message);
                        return [2 /*return*/, response];
                    case 2: return [4 /*yield*/, this.respostaParecerService.atualizar(resposta.id, resposta)];
                    case 3:
                        response = _a.sent();
                        this.swtAlert2Service.successAlert(response.message);
                        return [2 /*return*/, response];
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        err_12 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_12.error.errors);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCALFCAnswerDefaultComponent.prototype.salvarQuestionario = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, caminhoFormativoSugeridoFC;
            return tslib_1.__generator(this, function (_18) {
                switch (_18.label) {
                    case 0:
                        if (!!this.respostaVazia(this.resposta36)) return [3 /*break*/, 2];
                        this.resposta36.parecer_pergunta_id = 36;
                        _a = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta36)];
                    case 1:
                        _a.resposta36 = _18.sent();
                        _18.label = 2;
                    case 2:
                        if (!!this.respostaVazia(this.resposta37)) return [3 /*break*/, 4];
                        this.resposta37.parecer_pergunta_id = 37;
                        _b = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta37)];
                    case 3:
                        _b.resposta37 = _18.sent();
                        _18.label = 4;
                    case 4:
                        if (!!this.respostaVazia(this.resposta38)) return [3 /*break*/, 6];
                        this.resposta38.parecer_pergunta_id = 38;
                        _c = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta38)];
                    case 5:
                        _c.resposta38 = _18.sent();
                        _18.label = 6;
                    case 6:
                        if (!!this.respostaVazia(this.resposta39)) return [3 /*break*/, 8];
                        this.resposta39.parecer_pergunta_id = 39;
                        _d = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta39)];
                    case 7:
                        _d.resposta39 = _18.sent();
                        _18.label = 8;
                    case 8:
                        if (!!this.respostaVazia(this.resposta40)) return [3 /*break*/, 10];
                        this.resposta40.parecer_pergunta_id = 40;
                        _e = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta40)];
                    case 9:
                        _e.resposta40 = _18.sent();
                        _18.label = 10;
                    case 10:
                        if (!!this.respostaVazia(this.resposta41)) return [3 /*break*/, 12];
                        this.resposta41.parecer_pergunta_id = 41;
                        _f = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta41)];
                    case 11:
                        _f.resposta41 = _18.sent();
                        _18.label = 12;
                    case 12:
                        if (!!this.respostaVazia(this.resposta42)) return [3 /*break*/, 14];
                        this.resposta42.parecer_pergunta_id = 42;
                        _g = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta42)];
                    case 13:
                        _g.resposta42 = _18.sent();
                        _18.label = 14;
                    case 14:
                        if (!!this.respostaVazia(this.resposta43)) return [3 /*break*/, 16];
                        this.resposta43.parecer_pergunta_id = 43;
                        _h = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta43)];
                    case 15:
                        _h.resposta43 = _18.sent();
                        _18.label = 16;
                    case 16:
                        if (!!this.respostaVazia(this.resposta44)) return [3 /*break*/, 18];
                        this.resposta44.parecer_pergunta_id = 44;
                        _j = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta44)];
                    case 17:
                        _j.resposta44 = _18.sent();
                        _18.label = 18;
                    case 18:
                        if (!!this.respostaVazia(this.resposta45)) return [3 /*break*/, 20];
                        this.resposta45.parecer_pergunta_id = 45;
                        _k = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta45)];
                    case 19:
                        _k.resposta45 = _18.sent();
                        _18.label = 20;
                    case 20:
                        if (!!this.respostaVazia(this.resposta46)) return [3 /*break*/, 22];
                        this.resposta46.parecer_pergunta_id = 46;
                        _l = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta46)];
                    case 21:
                        _l.resposta46 = _18.sent();
                        _18.label = 22;
                    case 22:
                        if (!!this.respostaVazia(this.resposta47)) return [3 /*break*/, 24];
                        this.resposta47.parecer_pergunta_id = 47;
                        _m = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta47)];
                    case 23:
                        _m.resposta47 = _18.sent();
                        _18.label = 24;
                    case 24:
                        if (!!this.respostaVazia(this.resposta48)) return [3 /*break*/, 26];
                        this.resposta48.parecer_pergunta_id = 48;
                        _o = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta48)];
                    case 25:
                        _o.resposta48 = _18.sent();
                        _18.label = 26;
                    case 26:
                        if (!!this.respostaVazia(this.resposta49)) return [3 /*break*/, 28];
                        this.resposta49.parecer_pergunta_id = 49;
                        _p = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta49)];
                    case 27:
                        _p.resposta49 = _18.sent();
                        _18.label = 28;
                    case 28:
                        if (!!this.respostaVazia(this.resposta50)) return [3 /*break*/, 30];
                        this.resposta50.parecer_pergunta_id = 50;
                        _q = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta50)];
                    case 29:
                        _q.resposta50 = _18.sent();
                        _18.label = 30;
                    case 30:
                        if (!!this.respostaVazia(this.resposta51)) return [3 /*break*/, 32];
                        this.resposta51.parecer_pergunta_id = 51;
                        _r = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta51)];
                    case 31:
                        _r.resposta51 = _18.sent();
                        _18.label = 32;
                    case 32:
                        if (!!this.respostaVazia(this.resposta52)) return [3 /*break*/, 34];
                        this.resposta52.parecer_pergunta_id = 52;
                        _s = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta52)];
                    case 33:
                        _s.resposta52 = _18.sent();
                        _18.label = 34;
                    case 34:
                        if (!!this.respostaVazia(this.resposta53)) return [3 /*break*/, 36];
                        this.resposta53.parecer_pergunta_id = 53;
                        _t = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta53)];
                    case 35:
                        _t.resposta53 = _18.sent();
                        _18.label = 36;
                    case 36:
                        if (!!this.respostaVazia(this.resposta54)) return [3 /*break*/, 38];
                        this.resposta54.parecer_pergunta_id = 54;
                        _u = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta54)];
                    case 37:
                        _u.resposta54 = _18.sent();
                        _18.label = 38;
                    case 38:
                        if (!!this.respostaVazia(this.resposta55)) return [3 /*break*/, 40];
                        this.resposta55.parecer_pergunta_id = 55;
                        _v = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta55)];
                    case 39:
                        _v.resposta55 = _18.sent();
                        _18.label = 40;
                    case 40:
                        if (!!this.respostaVazia(this.resposta56)) return [3 /*break*/, 42];
                        this.resposta56.parecer_pergunta_id = 56;
                        _w = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta56)];
                    case 41:
                        _w.resposta56 = _18.sent();
                        _18.label = 42;
                    case 42:
                        if (!!this.respostaVazia(this.resposta57)) return [3 /*break*/, 44];
                        this.resposta57.parecer_pergunta_id = 57;
                        _x = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta57)];
                    case 43:
                        _x.resposta57 = _18.sent();
                        _18.label = 44;
                    case 44:
                        if (!!this.respostaVazia(this.resposta58)) return [3 /*break*/, 46];
                        this.resposta58.parecer_pergunta_id = 58;
                        _y = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta58)];
                    case 45:
                        _y.resposta58 = _18.sent();
                        _18.label = 46;
                    case 46:
                        if (!!this.respostaVazia(this.resposta59)) return [3 /*break*/, 48];
                        this.resposta59.parecer_pergunta_id = 59;
                        _z = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta59)];
                    case 47:
                        _z.resposta59 = _18.sent();
                        _18.label = 48;
                    case 48:
                        if (!!this.respostaVazia(this.resposta60)) return [3 /*break*/, 50];
                        this.resposta60.parecer_pergunta_id = 60;
                        _0 = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta60)];
                    case 49:
                        _0.resposta60 = _18.sent();
                        _18.label = 50;
                    case 50:
                        if (!!this.respostaVazia(this.resposta61)) return [3 /*break*/, 52];
                        this.resposta61.parecer_pergunta_id = 61;
                        _1 = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta61)];
                    case 51:
                        _1.resposta61 = _18.sent();
                        _18.label = 52;
                    case 52:
                        if (!!this.respostaVazia(this.resposta62)) return [3 /*break*/, 54];
                        this.resposta62.parecer_pergunta_id = 62;
                        _2 = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta62)];
                    case 53:
                        _2.resposta62 = _18.sent();
                        _18.label = 54;
                    case 54:
                        if (!!this.respostaVazia(this.resposta63)) return [3 /*break*/, 56];
                        this.resposta63.parecer_pergunta_id = 63;
                        _3 = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta63)];
                    case 55:
                        _3.resposta63 = _18.sent();
                        _18.label = 56;
                    case 56:
                        if (!!this.respostaVazia(this.resposta64)) return [3 /*break*/, 58];
                        this.resposta64.parecer_pergunta_id = 64;
                        _4 = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta64)];
                    case 57:
                        _4.resposta64 = _18.sent();
                        _18.label = 58;
                    case 58:
                        if (!!this.respostaVazia(this.resposta65)) return [3 /*break*/, 60];
                        this.resposta65.parecer_pergunta_id = 65;
                        _5 = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta65)];
                    case 59:
                        _5.resposta65 = _18.sent();
                        _18.label = 60;
                    case 60:
                        if (!!this.respostaVazia(this.resposta66)) return [3 /*break*/, 62];
                        this.resposta66.parecer_pergunta_id = 66;
                        _6 = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta66)];
                    case 61:
                        _6.resposta66 = _18.sent();
                        _18.label = 62;
                    case 62:
                        if (!!this.respostaVazia(this.resposta67)) return [3 /*break*/, 64];
                        this.resposta67.parecer_pergunta_id = 67;
                        _7 = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta67)];
                    case 63:
                        _7.resposta67 = _18.sent();
                        _18.label = 64;
                    case 64:
                        if (!!this.respostaVazia(this.resposta68)) return [3 /*break*/, 66];
                        this.resposta68.parecer_pergunta_id = 68;
                        _8 = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta68)];
                    case 65:
                        _8.resposta68 = _18.sent();
                        _18.label = 66;
                    case 66:
                        if (!!this.respostaVazia(this.resposta69)) return [3 /*break*/, 68];
                        this.resposta69.parecer_pergunta_id = 69;
                        _9 = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta69)];
                    case 67:
                        _9.resposta69 = _18.sent();
                        _18.label = 68;
                    case 68:
                        if (!!this.respostaVazia(this.resposta70)) return [3 /*break*/, 70];
                        this.resposta70.parecer_pergunta_id = 70;
                        _10 = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta70)];
                    case 69:
                        _10.resposta70 = _18.sent();
                        _18.label = 70;
                    case 70:
                        if (!!this.respostaVazia(this.resposta71)) return [3 /*break*/, 72];
                        this.resposta71.parecer_pergunta_id = 71;
                        _11 = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta71)];
                    case 71:
                        _11.resposta71 = _18.sent();
                        _18.label = 72;
                    case 72:
                        if (!!this.respostaVazia(this.resposta72)) return [3 /*break*/, 74];
                        this.resposta72.parecer_pergunta_id = 72;
                        _12 = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta72)];
                    case 73:
                        _12.resposta72 = _18.sent();
                        _18.label = 74;
                    case 74:
                        if (!!this.respostaVazia(this.resposta73)) return [3 /*break*/, 76];
                        this.resposta73.parecer_pergunta_id = 73;
                        _13 = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta73)];
                    case 75:
                        _13.resposta73 = _18.sent();
                        _18.label = 76;
                    case 76:
                        if (!!this.respostaVazia(this.resposta74)) return [3 /*break*/, 78];
                        this.resposta74.parecer_pergunta_id = 74;
                        _14 = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta74)];
                    case 77:
                        _14.resposta74 = _18.sent();
                        _18.label = 78;
                    case 78:
                        if (!!this.respostaVazia(this.resposta75)) return [3 /*break*/, 80];
                        this.resposta75.parecer_pergunta_id = 75;
                        this.questionarioFormando.indicacaoFC = parseInt(this.resposta75.resposta, 10);
                        _15 = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta75)];
                    case 79:
                        _15.resposta75 = _18.sent();
                        _18.label = 80;
                    case 80:
                        if (!!this.respostaVazia(this.resposta76)) return [3 /*break*/, 82];
                        this.resposta76.parecer_pergunta_id = 76;
                        this.questionarioFormando.justificativaFC = this.resposta76.resposta;
                        _16 = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta76)];
                    case 81:
                        _16.resposta76 = _18.sent();
                        _18.label = 82;
                    case 82:
                        if (!!this.respostaVazia(this.resposta77)) return [3 /*break*/, 84];
                        this.resposta77.parecer_pergunta_id = 77;
                        this.caminhoFormativoFormando.caminhoFormativoSugeridoFC = this.resposta77.resposta;
                        this.questionarioFormando.caminhoFormativoSugeridoFC = this.resposta77.resposta;
                        _17 = this;
                        return [4 /*yield*/, this.atualizarResposta(this.resposta77)];
                    case 83:
                        _17.resposta77 = _18.sent();
                        _18.label = 84;
                    case 84:
                        caminhoFormativoSugeridoFC = this.caminhoFormativoFormando.caminhoFormativoSugeridoFC;
                        return [4 /*yield*/, this.atualizarParecerResponsavel("INICIADO")];
                    case 85:
                        _18.sent();
                        if (!!this.caminhoFormativoFormando.id) return [3 /*break*/, 87];
                        return [4 /*yield*/, this.carregarCaminhoFormativo()];
                    case 86:
                        _18.sent();
                        this.caminhoFormativoFormando.caminhoFormativoSugeridoFC =
                            caminhoFormativoSugeridoFC;
                        _18.label = 87;
                    case 87:
                        this.atualizarCaminhoFormativo();
                        this.atualizarQuestionarioFormando();
                        return [2 /*return*/];
                }
            });
        });
    };
    ParecerCALFCAnswerDefaultComponent.prototype.definirStatusQuestionario = function () {
        var status = "INICIADO";
        var todoRespondido = false;
        if (!this.respostaVazia(this.resposta36) &&
            !this.respostaVazia(this.resposta37) &&
            !this.respostaVazia(this.resposta38) &&
            !this.respostaVazia(this.resposta39) &&
            !this.respostaVazia(this.resposta40) &&
            !this.respostaVazia(this.resposta41) &&
            !this.respostaVazia(this.resposta42) &&
            !this.respostaVazia(this.resposta43) &&
            !this.respostaVazia(this.resposta44) &&
            !this.respostaVazia(this.resposta45) &&
            !this.respostaVazia(this.resposta46) &&
            !this.respostaVazia(this.resposta47) &&
            // !this.respostaVazia(this.resposta33) && - Aqui é o caminho formativo proposto pelo FC
            !this.respostaVazia(this.resposta48) &&
            !this.respostaVazia(this.resposta49) &&
            !this.respostaVazia(this.resposta40) &&
            !this.respostaVazia(this.resposta51) &&
            !this.respostaVazia(this.resposta52) &&
            !this.respostaVazia(this.resposta53) &&
            !this.respostaVazia(this.resposta54) &&
            !this.respostaVazia(this.resposta55) &&
            !this.respostaVazia(this.resposta56) &&
            !this.respostaVazia(this.resposta57) &&
            !this.respostaVazia(this.resposta58) &&
            !this.respostaVazia(this.resposta59) &&
            !this.respostaVazia(this.resposta60) &&
            !this.respostaVazia(this.resposta61) &&
            !this.respostaVazia(this.resposta62) &&
            !this.respostaVazia(this.resposta63) &&
            !this.respostaVazia(this.resposta64) &&
            !this.respostaVazia(this.resposta65) &&
            !this.respostaVazia(this.resposta66) &&
            !this.respostaVazia(this.resposta67) &&
            !this.respostaVazia(this.resposta68) &&
            !this.respostaVazia(this.resposta69) &&
            !this.respostaVazia(this.resposta70) &&
            !this.respostaVazia(this.resposta71) &&
            !this.respostaVazia(this.resposta72) &&
            !this.respostaVazia(this.resposta73) &&
            !this.respostaVazia(this.resposta74) &&
            !this.respostaVazia(this.resposta75) &&
            !this.respostaVazia(this.resposta76) &&
            !this.respostaVazia(this.resposta77)) {
            todoRespondido = true;
        }
        if (todoRespondido) {
            status = "ENVIADO";
        }
        return status;
    };
    ParecerCALFCAnswerDefaultComponent.prototype.respostaVazia = function (resposta) {
        return (resposta.resposta == null ||
            resposta.resposta == undefined ||
            resposta.resposta == "");
    };
    ParecerCALFCAnswerDefaultComponent.prototype.enviarQuestionario = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.salvarQuestionario()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.atualizarParecerResponsavel("ENVIADO")];
                    case 2:
                        _a.sent();
                        this.router.navigate(["secured/parecer-agradecimento"]);
                        return [2 /*return*/];
                }
            });
        });
    };
    ParecerCALFCAnswerDefaultComponent.prototype.atualizarParecerResponsavel = function (status) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_13;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        if (!(this.parecerResponsavel.id != null)) return [3 /*break*/, 2];
                        this.parecerResponsavel.status = status;
                        if (status == "ENVIADO") {
                            this.parecerResponsavel.data_envio = new Date();
                        }
                        return [4 /*yield*/, this.parecerResponsavelService.atualizar(this.parecerResponsavel.id, this.parecerResponsavel)];
                    case 1:
                        response = _a.sent();
                        this.parecerResponsavel = response;
                        this.swtAlert2Service.successAlert(response.message);
                        _a.label = 2;
                    case 2: return [3 /*break*/, 4];
                    case 3:
                        err_13 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_13.error.errors);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return ParecerCALFCAnswerDefaultComponent;
}());
export { ParecerCALFCAnswerDefaultComponent };
