import * as i0 from "@angular/core";
var CacheService = /** @class */ (function () {
    function CacheService() {
        this.cache = {};
    }
    // Salva os dados no cache com uma chave específica
    CacheService.prototype.set = function (key, data) {
        this.cache[key] = data;
    };
    // Recupera os dados do cache com base na chave
    CacheService.prototype.get = function (key) {
        return this.cache[key];
    };
    // Verifica se há dados no cache
    CacheService.prototype.has = function (key) {
        return this.cache.hasOwnProperty(key);
    };
    // Limpa o cache (se precisar)
    CacheService.prototype.clear = function () {
        this.cache = {};
    };
    CacheService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CacheService_Factory() { return new CacheService(); }, token: CacheService, providedIn: "root" });
    return CacheService;
}());
export { CacheService };
