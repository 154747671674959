import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { GenericService } from 'src/app/core/services/generic.service';
var LoginService = /** @class */ (function (_super) {
    tslib_1.__extends(LoginService, _super);
    function LoginService(http, router, swtAlert2Service, cacheService) {
        var _this = _super.call(this, http, router) || this;
        _this.http = http;
        _this.router = router;
        _this.swtAlert2Service = swtAlert2Service;
        _this.cacheService = cacheService;
        return _this;
    }
    LoginService.prototype.fazerLogin = function (login) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        localStorage.clear();
                        sessionStorage.clear();
                        return [4 /*yield*/, this.getToken(login)];
                    case 1:
                        response = _a.sent();
                        // this.router.navigate(['/', 'secured']);
                        window.location.replace("secured");
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        if (e_1.error.error === "unauthorized")
                            this.swtAlert2Service.errorAlert('Acesso Negado' + ' - ' + e_1.error.error_description);
                        else
                            this.swtAlert2Service.errorAlert(e_1.error.error + ' - ' + e_1.error.error_description);
                        console.log(e_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    LoginService.prototype.fazerLogout = function () {
        localStorage.clear();
        sessionStorage.clear();
        this.token = undefined;
        this.cacheService.clear();
        this.router.navigate(['/', 'login']);
        //window.location.replace("/");
    };
    return LoginService;
}(GenericService));
export { LoginService };
