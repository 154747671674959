import * as tslib_1 from "tslib";
import { GenericService } from "src/app/core/services/generic.service";
import { environment } from "src/environments/environment";
var ElementoGrupoDominioLocalService = /** @class */ (function (_super) {
    tslib_1.__extends(ElementoGrupoDominioLocalService, _super);
    function ElementoGrupoDominioLocalService(http, router, cacheService) {
        var _this = _super.call(this, http, router) || this;
        _this.http = http;
        _this.router = router;
        _this.cacheService = cacheService;
        _this.setEntityType("elementogrupodominio");
        return _this;
    }
    ElementoGrupoDominioLocalService.prototype.buscarElementosGrupoDominio = function (busca) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var cacheKey;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                cacheKey = 'buscarElementosGrupoDominioLocal-' + JSON.stringify(busca);
                // Verifica se os dados já estão no cache
                if (this.cacheService.has(cacheKey)) {
                    return [2 /*return*/, Promise.resolve(this.cacheService.get(cacheKey))];
                }
                // Caso não tenha no cache, faz a requisição HTTP    
                return [2 /*return*/, this.http
                        .post(this.apiURL + "/buscar", JSON.stringify(busca), {
                        headers: this.getHeaders(),
                    })
                        .toPromise()
                        .then(function (response) {
                        _this.cacheService.set(cacheKey, response); // Armazena no cache
                        return response;
                    })];
            });
        });
    };
    ElementoGrupoDominioLocalService.prototype.buscarPorGrupoDominioNome = function (busca) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var cacheKey;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                cacheKey = 'buscarPorGrupoDominioLocalNome-' + JSON.stringify(busca);
                // Verifica se os dados já estão no cache
                if (this.cacheService.has(cacheKey)) {
                    return [2 /*return*/, Promise.resolve(this.cacheService.get(cacheKey))];
                }
                // Caso não tenha no cache, faz a requisição HTTP    
                return [2 /*return*/, this.http
                        .post(this.apiURL + "/pornomegrupo", JSON.stringify(busca), {
                        headers: this.getHeaders(),
                    })
                        .toPromise()
                        .then(function (response) {
                        _this.cacheService.set(cacheKey, response); // Armazena no cache
                        return response;
                    })];
            });
        });
    };
    ElementoGrupoDominioLocalService.prototype.buscarPorGrupoDominioNomeModuloFormacao = function (nome) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var cacheKey, url;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                cacheKey = 'buscarPorGrupoDominioLocalNomeModuloFormacao-' + JSON.stringify(nome);
                // Verifica se os dados já estão no cache
                if (this.cacheService.has(cacheKey)) {
                    return [2 /*return*/, Promise.resolve(this.cacheService.get(cacheKey))];
                }
                url = environment.moduloFormacao.urlModuloFormacao + "secured/elementogrupodominio/pornomegrupo?nome=" + nome;
                return [2 /*return*/, this.http.get(url, { headers: this.getHeaders() }).toPromise().then(function (response) {
                        _this.cacheService.set(cacheKey, response); // Armazena no cache
                        return response;
                    })];
            });
        });
    };
    return ElementoGrupoDominioLocalService;
}(GenericService));
export { ElementoGrupoDominioLocalService };
