import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
var CelulasComissaoDiscernimentoComponent = /** @class */ (function () {
    function CelulasComissaoDiscernimentoComponent(usuarioService, pessoaService, regionalService, missaoService, paisService, estadoService, centroEvangelizacaoService, celulaService, elementoGrupoDominioService, swtAlert2Service) {
        this.usuarioService = usuarioService;
        this.pessoaService = pessoaService;
        this.regionalService = regionalService;
        this.missaoService = missaoService;
        this.paisService = paisService;
        this.estadoService = estadoService;
        this.centroEvangelizacaoService = centroEvangelizacaoService;
        this.celulaService = celulaService;
        this.elementoGrupoDominioService = elementoGrupoDominioService;
        this.swtAlert2Service = swtAlert2Service;
        this.fases = new Array();
        this.centrosEvangelizacao = new Array();
        this.busca = {
            nome: null,
            ano: null,
            faseId: null,
            centroEvangelizacaoId: null,
            pageNumber: 0,
            quantityOfElements: 10,
        };
    }
    CelulasComissaoDiscernimentoComponent.prototype.ngOnInit = function () {
        this.initializer();
    };
    CelulasComissaoDiscernimentoComponent.prototype.initializer = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.carregarUsuario()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.carregarPessoa()];
                    case 2:
                        _a.sent();
                        this.carregarNiveisDaCelula();
                        this.buscarCelulas();
                        return [2 /*return*/];
                }
            });
        });
    };
    CelulasComissaoDiscernimentoComponent.prototype.carregarUsuario = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.usuarioService.getCurrentUser()];
                    case 1:
                        response = _a.sent();
                        this.usuario = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        console.log(e_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    CelulasComissaoDiscernimentoComponent.prototype.carregarPessoa = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.pessoaService.buscaPorUsuarioId(this.usuario.id)];
                    case 1:
                        response = _a.sent();
                        this.pessoa = response.entity;
                        if (this.pessoa.id) {
                            if (this.pessoa.vinculoMissao.missaoAtualId) {
                                this.carregarCevPorMissao(this.pessoa.vinculoMissao.missaoAtualId);
                            }
                            else if (this.pessoa.vinculoDifusao.difusaoAtualId) {
                                this.carregarCevPorDifusao(this.pessoa.vinculoDifusao.difusaoAtualId);
                            }
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        e_2 = _a.sent();
                        console.log(e_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    CelulasComissaoDiscernimentoComponent.prototype.cliqueNoBotaoListener = function (botao) {
        this.busca.pageNumber = botao.numero - 1;
        this.buscarCelulas();
    };
    CelulasComissaoDiscernimentoComponent.prototype.carregarCevPorMissao = function (missaoId) {
        var _this = this;
        this.centroEvangelizacaoService
            .porMissao(missaoId)
            .then(function (response) {
            _this.centrosEvangelizacao = response.entity;
        })
            .catch(function (err) {
            _this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    };
    CelulasComissaoDiscernimentoComponent.prototype.carregarCevPorDifusao = function (difusaoId) {
        var _this = this;
        this.centroEvangelizacaoService
            .porDifusao(difusaoId)
            .then(function (response) {
            _this.centrosEvangelizacao = response.entity;
        })
            .catch(function (err) {
            _this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    };
    CelulasComissaoDiscernimentoComponent.prototype.carregarNiveisDaCelula = function () {
        var _this = this;
        var buscaFase = {
            grupoNome: "FASE_CELULA",
        };
        this.elementoGrupoDominioService
            .buscarPorGrupoDominioNome(buscaFase)
            .then(function (lista) {
            _this.fases = lista.entity;
        })
            .catch(function (err) {
            _this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    };
    CelulasComissaoDiscernimentoComponent.prototype.buscarCelulas = function () {
        var _this = this;
        this.celulaService
            .buscarCelulas(this.busca)
            .then(function (response) {
            _this.celulas = response.entity;
            _this.totalDePaginas = response.quantity;
        })
            .catch(function (err) {
            _this.swtAlert2Service.errorAlert(err.error.errors);
        });
    };
    return CelulasComissaoDiscernimentoComponent;
}());
export { CelulasComissaoDiscernimentoComponent };
