import { BehaviorSubject } from "rxjs";
import * as i0 from "@angular/core";
var LoggedInUserSharedDataService = /** @class */ (function () {
    function LoggedInUserSharedDataService() {
        this.loggedInUser$ = new BehaviorSubject(null);
        this.loggedInPerson$ = new BehaviorSubject(null);
    }
    LoggedInUserSharedDataService.prototype.updateLoggedInUser = function (user) {
        this.loggedInUser$.next(user);
    };
    LoggedInUserSharedDataService.prototype.updateLoggedInPerson = function (person) {
        this.loggedInPerson$.next(person);
    };
    LoggedInUserSharedDataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoggedInUserSharedDataService_Factory() { return new LoggedInUserSharedDataService(); }, token: LoggedInUserSharedDataService, providedIn: "root" });
    return LoggedInUserSharedDataService;
}());
export { LoggedInUserSharedDataService };
