import * as tslib_1 from "tslib";
import { GenericService } from "src/app/core/services/generic.service";
var PessoaService = /** @class */ (function (_super) {
    tslib_1.__extends(PessoaService, _super);
    function PessoaService(http, router, cacheService) {
        var _this = _super.call(this, http, router) || this;
        _this.http = http;
        _this.router = router;
        _this.cacheService = cacheService;
        _this.setEntityType("r/pessoa");
        return _this;
    }
    PessoaService.prototype.atualizarFormaVida = function (request) {
        return this.http
            .post(this.apiURL + "/atualizarformavida", JSON.stringify(request), {
            headers: this.getHeaders(),
        })
            .toPromise();
    };
    PessoaService.prototype.atualizarEstadoVida = function (request) {
        return this.http
            .post(this.apiURL + "/atualizarestadovida", JSON.stringify(request), { headers: this.getHeaders() })
            .toPromise();
    };
    PessoaService.prototype.buscarPessoas = function (busca) {
        var _this = this;
        var cacheKey = "buscarPessoas-" + JSON.stringify(busca);
        // Verifica se os dados já estão no cache
        if (this.cacheService.has(cacheKey)) {
            return Promise.resolve(this.cacheService.get(cacheKey));
        }
        // Caso não tenha no cache, faz a requisição HTTP
        return this.http
            .post(this.apiURL + "/buscar", JSON.stringify(busca), {
            headers: this.getHeaders(),
        })
            .toPromise()
            .then(function (response) {
            _this.cacheService.set(cacheKey, response); // Armazena no cache
            return response;
        });
    };
    PessoaService.prototype.buscarMembrosCasaComunitaria = function (busca) {
        var _this = this;
        var cacheKey = "buscarMembrosCasaComunitaria-" + JSON.stringify(busca);
        // Verifica se os dados já estão no cache
        if (this.cacheService.has(cacheKey)) {
            return Promise.resolve(this.cacheService.get(cacheKey));
        }
        // Caso não tenha no cache, faz a requisição HTTP
        return this.http
            .post(this.apiURL + "/buscarmembroscasacomunitaria", JSON.stringify(busca), { headers: this.getHeaders() })
            .toPromise()
            .then(function (response) {
            _this.cacheService.set(cacheKey, response); // Armazena no cache
            return response;
        });
    };
    PessoaService.prototype.pesquisarPessoasInfoListagem = function (busca) {
        var _this = this;
        var cacheKey = "pesquisarPessoasInfoListagem-" + JSON.stringify(busca);
        // Verifica se os dados já estão no cache
        if (this.cacheService.has(cacheKey)) {
            return Promise.resolve(this.cacheService.get(cacheKey));
        }
        // Caso não tenha no cache, faz a requisição HTTP
        return this.http
            .post(this.apiURL + "/pesquisarpessoainfolistagem", JSON.stringify(busca), { headers: this.getHeaders() })
            .toPromise()
            .then(function (response) {
            _this.cacheService.set(cacheKey, response); // Armazena no cache
            return response;
        });
    };
    PessoaService.prototype.buscaPorUsuarioId = function (id, refreshCache) {
        var _this = this;
        if (refreshCache === void 0) { refreshCache = false; }
        var cacheKey = "buscaPorUsuarioId-" + JSON.stringify(id);
        if (!refreshCache) {
            // Verifica se os dados já estão no cache
            if (this.cacheService.has(cacheKey)) {
                return Promise.resolve(this.cacheService.get(cacheKey));
            }
        }
        // Caso não tenha no cache, faz a requisição HTTP
        return this.http
            .get(this.apiURL + "/porusuario/" + id, {
            headers: this.getHeaders(),
        })
            .toPromise()
            .then(function (response) {
            _this.cacheService.set(cacheKey, response); // Armazena no cache
            return response;
        });
    };
    PessoaService.prototype.buscaPorId = function (id) {
        var _this = this;
        var cacheKey = "buscaPorId-" + JSON.stringify(id);
        // Verifica se os dados já estão no cache
        if (this.cacheService.has(cacheKey)) {
            return Promise.resolve(this.cacheService.get(cacheKey));
        }
        // Caso não tenha no cache, faz a requisição HTTP
        return this.http
            .get(this.apiURL + "/" + id, { headers: this.getHeaders() })
            .toPromise()
            .then(function (response) {
            _this.cacheService.set(cacheKey, response); // Armazena no cache
            return response;
        });
    };
    PessoaService.prototype.buscaPessoaPorUsuarioCriador = function () {
        return this.http
            .get(this.apiURL + "/porusuariocriador", {
            headers: this.getHeaders(),
        })
            .toPromise();
    };
    PessoaService.prototype.acompanhadoresGrupoOracao = function (id) {
        return this.http
            .get(this.apiURL + "/acompanhadoresgrupooracao/" + id, {
            headers: this.getHeaders(),
        })
            .toPromise();
    };
    PessoaService.prototype.setarRegistroDuplicado = function (id) {
        return this.http
            .get(this.apiURL + "/registroduplicado/" + id, {
            headers: this.getHeaders(),
        })
            .toPromise();
    };
    return PessoaService;
}(GenericService));
export { PessoaService };
