import * as tslib_1 from "tslib";
import { GenericService } from 'src/app/core/services/generic.service';
var ElementoGrupoDominioService = /** @class */ (function (_super) {
    tslib_1.__extends(ElementoGrupoDominioService, _super);
    function ElementoGrupoDominioService(http, router, cacheService) {
        var _this = _super.call(this, http, router) || this;
        _this.http = http;
        _this.router = router;
        _this.cacheService = cacheService;
        _this.setEntityType('r/elementogrupodominio');
        return _this;
    }
    ElementoGrupoDominioService.prototype.buscarElementosGrupoDominio = function (busca) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var cacheKey;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                cacheKey = 'buscarElementosGrupoDominio-' + JSON.stringify(busca);
                // Verifica se os dados já estão no cache
                if (this.cacheService.has(cacheKey)) {
                    return [2 /*return*/, Promise.resolve(this.cacheService.get(cacheKey))];
                }
                // Caso não tenha no cache, faz a requisição HTTP        
                return [2 /*return*/, this.http.post(this.apiURL + '/buscar', JSON.stringify(busca), { 'headers': this.getHeaders() }).toPromise().then(function (response) {
                        _this.cacheService.set(cacheKey, response); // Armazena no cache
                        return response;
                    })];
            });
        });
    };
    ElementoGrupoDominioService.prototype.buscarPorGrupoDominioNome = function (busca) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var cacheKey;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                cacheKey = 'buscarPorGrupoDominioNome-' + JSON.stringify(busca);
                // Verifica se os dados já estão no cache
                if (this.cacheService.has(cacheKey)) {
                    return [2 /*return*/, Promise.resolve(this.cacheService.get(cacheKey))];
                }
                // Caso não tenha no cache, faz a requisição HTTP        
                return [2 /*return*/, this.http.post(this.apiURL + '/pornomegrupo', JSON.stringify(busca), { 'headers': this.getHeaders() }).toPromise().then(function (response) {
                        _this.cacheService.set(cacheKey, response); // Armazena no cache
                        return response;
                    })];
            });
        });
    };
    ElementoGrupoDominioService.prototype.getAllNotInAcessoUsuarioEstrutura = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var cacheKey;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                cacheKey = 'getAllNotInAcessoUsuarioEstrutura-' + JSON.stringify(id);
                // Verifica se os dados já estão no cache
                if (this.cacheService.has(cacheKey)) {
                    return [2 /*return*/, Promise.resolve(this.cacheService.get(cacheKey))];
                }
                // Caso não tenha no cache, faz a requisição HTTP        
                return [2 /*return*/, this.http.get(this.apiURL + "/getallnotinacessousuarioestrutura/" + id, { 'headers': this.getHeaders() }).toPromise().then(function (response) {
                        _this.cacheService.set(cacheKey, response); // Armazena no cache
                        return response;
                    })];
            });
        });
    };
    ElementoGrupoDominioService.prototype.getAllFromAcessoUsuarioEstrutura = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var cacheKey;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                cacheKey = 'getAllFromAcessoUsuarioEstrutura-' + JSON.stringify(id);
                // Verifica se os dados já estão no cache
                if (this.cacheService.has(cacheKey)) {
                    return [2 /*return*/, Promise.resolve(this.cacheService.get(cacheKey))];
                }
                // Caso não tenha no cache, faz a requisição HTTP        
                return [2 /*return*/, this.http.get(this.apiURL + "/getallfromacessousuarioestrutura/" + id, { 'headers': this.getHeaders() }).toPromise().then(function (response) {
                        _this.cacheService.set(cacheKey, response); // Armazena no cache
                        return response;
                    })];
            });
        });
    };
    return ElementoGrupoDominioService;
}(GenericService));
export { ElementoGrupoDominioService };
